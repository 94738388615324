@import './variables.less';

.forecastInfo {
    margin-top: 0px;
}

.forecastTitleContainer {
    background: @reportTitle;
    text-align: center;
    padding: 10px 0px;
    color: @white;
    .siteName {
        font-size: @font-size-4xl;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .forecastTitle {
        font-size: @font-size-4xl;
        font-weight: bold;
        line-height: normal;
    }
}


.fillContainer > .fillContent.forecastViewer,
.fillContainer > .fillContent.forecastDoc {
    transition: flex .6s;
}

.fillContainer.column > .fillContent.forecastViewer {
    flex-basis: auto!important;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    display: block;
}

.fillContainer.column > .fillContent.forecastDoc {
    padding: 0px
}
 
.forecastHeader {
    height: calc(100% - (@fillLine + @footerHeight));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height .6s;
}
.fillContainer.column > .fillHeader.forecastViewer {
    height: 100%;
}

.fillContainer.forecastContainer.active .forecastViewer {
    overflow-y: auto;
}

.forecastContainer {
    .forecastViewer { 
        .reportTopBar.initial {
            justify-content: center;
            display: flex;
            transition: max-height .6s 0s, opacity .4s 0s;
            max-height: 600px;
            opacity: 1;
            position: sticky;
            height: 100%;
            padding-bottom: 0px;
        }

        .reportTopBar.filter {
            display: flex;
            transition: visibility .6s, height .6s;
            height: 0px;
            overflow-y: auto;
            visibility: hidden;
        }
    }
}

.forecastContainer.active {
    .forecastViewer {
        overflow: hidden;

        .reportTopBar.initial {
            overflow-y: auto;
            max-height: 0px;
            opacity: 0;
        }

        .reportTopBar.filter {
            display: flex;
            visibility: visible;
            height: unset;
        }
    }

    .fillFooter {
        display: none
    }
} 

.forecastContainer.loading {
    .forecastViewer {
        .reportTopBar.initial {
            max-height: 0px;
            opacity: 0;
            visibility: hidden;
        }
        .reportTopBar.filter {
            transition: opacity .6s, 
        }
    }
}

.forecastContainer.active .forecastViewer .reportTopBar.filter {
    display: flex;
    opacity: 1;
}

.forecastDoc {
    height: 100%;
    overflow: auto;
    visibility: visible;
}

.deviceForecast {
    background: @reportTable;
    height: fit-content;
    padding: @base-padding * 4;
    border-radius: @baseBorderRadius * 5;
    margin: @base-margin * 2;
    text-align: center;
}

.deviceForecastTitle {
    display: flex;
    font-size: @font-size-l;
    color: @white;
    margin-bottom: @base-margin;
    font-weight: bold;
    justify-content: center;
    .title {
        width: 290px;
    }
}

.deviceForecastSubTitle {
    display: flex;
    font-size: @font-size-2xs;
    opacity: .7;
    color: @white;
    margin-bottom: @base-margin;
    font-weight: bold;
    justify-content: center;
}

.deviceForecast .forecastTable {
    width: 100%;
    border-collapse: collapse;
    color: @white;
    th {
        border-bottom: 1px solid @lightGrey;
        text-align: center;
    }
    
    tr {
        border-bottom: 1px solid @lightGrey;
        td:first-child {
            text-align: justify;
        }
    }

    td:first-child:before {
        content: '';
        display: block; 
        min-width: 205px;
    }

    tr:last-child {
        border-bottom: none;
    }

    td {
        text-align: center;
    }
}

.forecastViewer .dynList.error {
    border-color: @red!important;
}

.dynamicForm.forecastInfo table tr td:nth-child(2) {
    width: 400px;
}