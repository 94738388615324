@import './variables.less';

.ui.button.pdfButton { 
    font-size: @font-size-xs; 
    padding: @base-padding*2 @base-padding*6;
    margin: 0;
}

.pdfButton .innerText {
    visibility: visible;
}

.ui.button.pdfButton {
    border-color: @root-white;
    color: @root-white
}

.ui.button.pdfButton { 
    background-color: @pageBackground;  
    border-radius: @baseBorderRadius*4+2;
    border: @primaryBorderWidth @main-white solid;
}