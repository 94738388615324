@import "./variables.less";
.pageHeader.secondary{
    height: 40px;
}

.infoCardPreLoader {
    position: relative; 
    display: none; 
    justify-content: center; 
    align-items: center
}

.infoCardPreLoader > .ui.loader {
    top:unset;
    left: unset;
}

.infoCards {
    display: flex;
    flex-direction: row;
    padding-left: @base-padding*2;
    padding-right: @base-padding*2;
}

.cardBox.infoCard {
    background: gray;
}

.cardBox.infoCard.hidden {
    visibility: hidden!important;
}

.cardBox.infoCard:hover {
    cursor: pointer;
    background: fade(gray, 85%);
} 

.cardBox.infoCard .title {
    text-align: center;
    font-size: @font-size-m;
    white-space: nowrap;
}

.cardBox.infoCard .status {
    font-size: @font-size-2xl+4; 
    padding-bottom: @base-padding*5;
    text-align: center;
    min-height: 52px;
}

.recent {
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @root-white;
    font-size: @font-size-xl;
    padding-top: @base-padding;
    padding-bottom: @base-padding*1-1;
    padding-left: @base-padding*4;
    height: 100%;
    width: 100%;
}

table.dashInfo {
    padding-bottom: @base-padding*2;
    font-family: @primary-font-bold;
    font-size: @font-size-xs;
}

table.dashInfo tr td {
    padding-top: @base-padding;
}

table.dashInfo tr td:first-child {
    text-align: right;
    padding-right: @base-padding;
    font-family: @primary-font-black;
}

table.dashInfo tr td:first-child {
    display: flex;
    float: right;
}

.systemMessage table {
    padding-bottom: 0px;
}

.systemMessage table tr td {
    padding-top: @base-padding*2;
    padding-bottom: @base-padding*2;
}

.carousel.infoCarousel {
    width: 35%;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.carousel.infoCarousel .slideHolder {
    display: flex;
    align-items: center;
    height: 112px;
}

.carousel.infoCarousel .carousel-dots {
    padding-top: @base-padding;
}

.page .dashboard.row.middle.left {
    width: 70%;
}

.page .row.middle.right {
    width: 30%;
    height: 150px;
}

.page .ratings.row.middle.left {
    width: 50%;
}

.page .ratings.row.middle.right {
    width: 50%;
}

.carousel.infoCarousel > div {
    margin-bottom: 0px;
}

.dynamicForm.siteInfo table tr td:nth-child(2) {
    width: 400px;
}

/* ------------ New Weather Styles --------------- */

.weatherInfo {
    width: 100%;
    margin: @base-margin 0;
    border: @primaryBorderWidth solid @root-white;
    border-radius: @baseBorderRadius * 5;
    background-color: @infoLightBlue;
    box-shadow: inset 0px 0px @base * 5 @base * 5 transparent;
}

.weatherData {
    margin: @base-margin * 2 @base-margin * 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: @baseBorderRadius * 5
}


.weatherInfo .title {
    display: flex;
    flex-direction: column;
    margin: @base-margin * 2 @base-margin * 3;
    
    .location {
        font-family: @secondary-font-bold;
        font-size: @font-size-m;
        padding-bottom: @base-padding;
        filter: @siteDropShadow;

    }
    .date {
        font-size: @font-size-s;
        filter: @siteDropShadow;
    }
}

.temperatureData {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .temperatureValue {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: @font-size-5xl;
        margin: @base-margin * 2;
        filter: @siteDropShadow;
    }

    .weatherIcon {
        margin: @base-margin * 2;
        filter: @siteDropShadow;
    } 
}

.environmentData {
    display: flex;
    flex-wrap: wrap;
    margin-left: @base-margin * 3;
    
    .dataColumn {
        display: flex;
        flex-direction: column;
        margin-right: @base-margin * 3;
    }
    .dataRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: @base-margin+2 0;
    }
    .dataValue {
        display: flex;
        align-items: baseline;
        font-size: @font-size-s;
        font-family: @secondary-font-bold;
        padding-bottom: @base-padding / 2;
        filter: @siteDropShadow;

        .dataUnit {
            font-size: @font-size-2xs;
        }
    }
    .dataLabel {
        font-size: @font-size-xs;
    }
}

.weatherInfo hr {
    margin: 0 @base-margin * 3;
    border: none;
    border-radius: 2px;
    height: 2px;
    color: @root-white;
    background-color: @root-white;
    filter: @siteDropShadow;
}

/* ---------- MISC INFO STYLES ------------ */
.miscInfo {
    width: 100%;
    margin: @base-margin 0;
    border: @primaryBorderWidth solid @root-white;
    border-radius: @baseBorderRadius * 5;
    background-color: @infoLightOrange;

    box-shadow: inset 0px 0px @base * 5 @base * 5 transparent;
    .title {
        font-family: @secondary-font-bold;
        font-size: @font-size-m;
        margin: @base-margin * 2 @base-margin * 3;

    }
    .data {
        font-size: @font-size-s;
        margin: @base-margin * 2 @base-margin * 3;
    }
}

.miscInfo .data > a {
    color: @root-white;
    &:hover {
        color: @white
    }
}

.miscInfo hr {
    margin: 0 @base-margin * 3;
    border: none;
    border-radius: 2px;
    height: 2px;
    color: @root-white;
    background-color: @root-white;
    filter: @siteDropShadow;
}

.centeredLoader .ui.loader:before,
.centeredLoader .ui.loader:after {
    margin: 0px;
}

.deviceStrikethrough {
    display: flex;
}

.field.eye .ui.disabled.input input[type="password"] {
    font-size: @font-size-l;
}

.field.eye .ui.disabled.input input {
    padding-right: 8px !important;
}