@import './variables.less';

@rechartsPrintWidth: 1400px;
@printTextColor: black;

.print {
    .reportPage {
        height: 100%;
    }
    
    .recharts-responsive-container {
        width: 1400px!important
    }

    .recharts-text.recharts-cartesian-axis-tick-value {
        stroke: @printTextColor!important
    }

    .viewFilter {
        visibility: hidden;
    }

    .bar-chart-container {
        width: @rechartsPrintWidth !important
    }

    .line-chart-container .recharts-surface,
    .line-chart-container .recharts-wrapper,
    .bar-chart-container .recharts-surface,
    .bar-chart-container .recharts-wrapper {
        width: @rechartsPrintWidth !important
    }

    .recharts-cartesian-grid-horizontal line,
    .recharts-cartesian-grid-vertical line,
    .recharts-cartesian-axis-line,
    .xAxisTickLabel {
        stroke: @printTextColor !important
    }

    .recharts-text.recharts-label {
        stroke: @printTextColor !important;
        fill: @printTextColor !important
    }

    .chart-title {
        color: @printTextColor !important;
    }

    .gaugeTitle, 
    .gaugeThresh {
        fill: @printTextColor !important;
    }

    .bars > .barsTitle {
        color: @printTextColor
    }

    .barValue {
        fill: @printTextColor !important
    }

    .ui.buttons > .ui.button:not(.basic):not(.inverted), .ui.buttons:not(.basic):not(.inverted) > .button {
        box-shadow: none!important;
    }

    .ui.mini.buttons.legend-container > button {
        border-color: @printTextColor!important;
        color: @printTextColor!important
    }

    .footer {
        color: @printTextColor!important;
        position: relative;
        flex-wrap: wrap;
        display: block;
    }

    .footerContainer {
        display: none;
        visibility: hidden;
        margin: 0px;
        bottom: 0px;
        padding: 0px;
    }

    .pdfPage {
        height: calc(792pt - (@base-padding * 5));
    }

    .heatmapTable tr td:first-child,
    .heatmapTable tr:last-child td {
        color: black
    }
}