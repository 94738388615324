@import "./variables.less";

.ui.list.dynamicList {
    border: @secondaryBorder;
    border-radius: @baseBorderRadius*6;
    overflow-y: hidden;
}

.ui.list.dynamicList > div {
    overflow-y: auto;
}

/* Dynamic List Item */
.ui.list.dynamicList div > .item .header,
.ui.list.dynamicList div > .item i  {
    color: @root-white;
}

.ui.list.dynamicList div > .item i {
    cursor: pointer;
}

.ui.divided.list.dynamicList div > .item.none {
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 0;
    height: 100%;
}

.ui.list.dynamicList div > .item .content {
    display: flex;
    margin-bottom: @base-margin * 2;
    justify-content: space-between;
}

.ui.divided.list.dynamicList div > .item {
    border-color: @root-white;
    padding-top: @base-padding*4;
    padding-left: @base-padding*4;
    padding-right: @base-padding*4;
}

/* Dynamic List Item Search */
.itemSearch .ui.vertical.menu {
    border-radius: 0px 0px @baseBorderRadius*4 @baseBorderRadius*4;
    margin-top: (-@base-margin*2);
    position: absolute;
    width: 100%
}

.itemSearch .ui.input > .icon {
    color: @root-white;
}

.itemSearch.active  .ui.input > input,
.itemSearch.active  .ui.input > input:focus {
    border-radius: @baseBorderRadius*4 @baseBorderRadius*4 0px 0px;
}

.itemSearch.error input {
    border-color: @red !important;
}

/* Dynamic List Item Search Dropdown */
.itemSearch .ui.vertical.menu.searchMenu {
    height: 200px;
    overflow-y: hidden;
    background-color: @lightBlack;
    z-index: 1;
    border: @secondaryBorder;
    border-top: 0;
}

.itemSearch .ui.vertical.menu div > div > .item {
    height: 26px;
    color: @root-white;
    padding: @base-padding+2;
    width:100%;
}

.itemSearch .ui.vertical.menu div > div > .disabled.item {
    color: fade(@root-white,50%)!important;
}

.itemSearch .ui.input > i {
    color: @root-white;
}

.itemSearch .ui.input input {
    text-overflow: ellipsis
}

.dynList {
    position: relative;
}

.ui.form .dynList .field {
    margin: 0;
}

.itemView {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 0;
    flex-direction: row;
    flex-wrap: wrap;
}

.itemView > div {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.dynList.dynInput > .itemSearch .field .ui.input {
    width: 100%;
}

.dynList.dynInput > .itemSearch .field .ui.input input {
    color: @root-white;
    background: transparent;
    border: none;
}

.dynList.dynInput > .itemSearch .ui.vertical.searchMenu.menu {
    position: inherit;
    width: 100%!important;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.dynList.dynInput > .itemSearch .field .ui.input input:focus {
    border-color: transparent;
}

.dynList.dynInput .loader.tagLoader {
    position: relative;
    
}

.dynList.dynInput .loader.active.tagLoader{
    display: contents;
}

.dynList.dynInput .loader.tagLoader::before, 
.dynList.dynInput .loader.tagLoader::after {
    position: initial;
    margin: 0em;
    margin-top: @base-margin + 2;
    margin-left: 0px;
}

.dynList.dynInput .tag {
    border: @primaryBorder;
    margin-right: @base-margin;
    margin-top: @base-margin;
    background-color: @green;
    position: relative;
}

.viewFilter .dynList {
    padding: @base-padding * 2;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
}

.viewFilter .dynList > .itemSearch .field .ui.input {
    width: 100%;
    align-items: center;
}

.viewFilter .dynList > .itemSearch .field .ui.input input {
    color: @root-white;
    background: transparent;
    border: none;
}

.viewFilter .dynList > .itemSearch .ui.vertical.searchMenu.menu {
    position: inherit;
    width: 100%!important;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.viewFilter .dynList > .itemSearch .field .ui.input input:focus {
    border-color: transparent;
}

.viewFilter .dynList .loader.tagLoader {
    position: relative;
    
}

.viewFilter .dynList .loader.active.tagLoader{
    display: contents;
}

.viewFilter .dynList .loader.tagLoader::before, 
.viewFilter .dynList .loader.tagLoader::after {
    position: initial;
    margin: 0em;
    margin-top: @base-margin + 2;
    margin-left: 0px;
}

.viewFilter .dynList .tag {
    border: @primaryBorder;
    margin-right: @base-margin;
    margin-top: @base-margin;
    background-color: @green
}

.itemSearch .fas.fa-times {
    cursor: pointer;
}

.aresModalContent .itemSearch .fas.fa-times:not(.errorIcon){
    position: absolute;
    right: 18px;
}

.aresModalContent .dynList > .itemSearch .field .ui.input {
    align-items: center;
}

.viewFilter .itemSearch .fas.fa-times {
    margin-right: @base-margin * 2;
}

@aresFormGroupDynListPadding: (@base-padding * 2) - 1;

.aresFormGroup > .dynList > .itemSearch {
    padding: 0px @aresFormGroupDynListPadding;
} 

.aresFormGroup > .dynList > .itemSearch > .searchMenu {
    width: calc(100% - @aresFormGroupDynListPadding * 2)
}