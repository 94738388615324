@import "./variables.less";

.ui.segment.responsePortal {
    background-color: rgba(10,10,10, 1);
    color: @root-white;
    right: 30px;
    position: fixed; 
    bottom: 24px;
    z-index: 1000;
    border: @primaryBorder;
    filter: none!important;
    padding: (@base-padding * 4 + 2) @base-padding * 8;
}

.ui.segment.responsePortal.success {
    border-color: @green;
    color: @green;
}

.ui.segment.responsePortal.fail {
    border-color: @red;
    color: @red;
}