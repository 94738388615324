@import "./variables.less";

.tabContentHolder {
    pointer-events: all;
}

.tabContentHolder .ui.segment.active.tab {
    background: transparent;
    margin: 0;
    border: none;
    box-shadow: none;
}

.tabItems.ui.compact.inverted.menu {
    background: transparent;
}

.tabItems.ui.compact.inverted.menu .active.item {
    background: @greenAccent
}

.tabItems.ui.compact.inverted.menu a{
    font-family: @primary-font-bold;
    font-size: @font-size-s;
    justify-content: center;
    width: 130px;
    border-bottom: @primaryBorder;
    border-top:  @primaryBorder
}

.tabItems.ui.compact.inverted.menu a:first-child {
    border-bottom: @primaryBorder;
    border-top:  @primaryBorder;
    border-left:  @primaryBorder;
}

.tabItems.ui.compact.inverted.menu a:last-child {
    border-bottom: @primaryBorder;
    border-top:  @primaryBorder;
    border-right:  @primaryBorder;
}

.chartContainer {
    // height: 100%;
    width: 100%;
    display: flex;
    // overflow: auto;
    pointer-events: all;
}

.chartContent {
    pointer-events: all;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar-chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.line-chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

/* When Screen height is greater than 1000 px */
@media screen and (min-height:1000px) {
    .bar-chart-container {
        position: relative;
        width: 100%;
        height: 1000px;
    }

    .line-chart-container {
        position: relative;
        width: 100%;
        height: 900px;
    }
} 

.recharts-cartesian-axis-line{
    stroke: @main-white;
}

.recharts-cartesian-grid-horizontal line {
    stroke: @main-white;
}

.recharts-text.recharts-cartesian-axis-tick-value {
    font-family: @primary-font;
    letter-spacing: @primary-letter-spacing;
    stroke: @main-white;
}

.recharts-text.recharts-label {
    font-family: @primary-font;
    font-size: @font-size-m;
    letter-spacing: @primary-letter-spacing;
    stroke: @main-white;  
    fill: @main-white;
}

.recharts-text.recharts-label.pdf {
    font-family: @primary-font;
    font-size: @font-size-xs;
    letter-spacing: @primary-letter-spacing;
    stroke: @black !important;  
    fill: @black !important;
    .sub {
        font-size: @font-size-3xs;
    }
}

.xAxisTickLabel {
    stroke: @main-white;
}

/* --------------- TOOLTIP STYLES ----------------- */

.recharts-custom-tooltip {
    background-color: @darkGrey;
    padding: 10px;
    border-radius: 12px;
}

.recharts-custom-tooltip .tooltip-label {
    color: @main-white;
    font-size: @font-size-s;
    font-family: @primary-font;
}

.recharts-custom-tooltip .tooltip-items{
    font-size: @font-size-xs;
    font-family: @primary-font;
}

/* ------------ LEGEND STYLES --------------------- */

.chart-header {
    display: flex;
    position: relative;
    top: 16px;
    z-index: 1;
    &.linechart {
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 @base-margin*27;
    }
    &.barchart {
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 @base-margin * 20 0 @base-margin * 25;
    }
}

.chart-title {
    font-family: @primary-font-bold;
    letter-spacing: @primary-letter-spacing;
    font-size: @font-size-xl;
    text-align: center;
    color: @main-white;
    margin-bottom: @base-margin*5;
}

.barchart .chart-title-legend {
    display: flex;
    flex-direction: column;
}

.linechart .chart-title-legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.ui.inverted.button.back-button-visible {
    padding: @base-padding @base-padding*4;
    margin: 0;
    &.false {
        visibility: hidden;
    }
    &.true {
        box-shadow: 0px 0px 0px @primaryBorderWidth @main-white inset !important;
        
        &:focus {
            background: transparent;
            color: @main-white;
            box-shadow: 0px 0px 0px @primaryBorderWidth @main-white inset;      
        }
    }
}

.backButtonContent {
    font-size: @font-size-s;
}

.linechart .ui.mini.buttons.legend-container {
    flex-wrap: wrap;
    margin: 0 ((@base-margin*2) + 2);
    width: fit-content;
}

.barchart .ui.mini.buttons.legend-container {
    flex-wrap: wrap;
    margin: 0 @base-margin*3;
}

.ui.mini.buttons.legend-container > button {
    border: 2px solid @main-white;
    border-radius: 10px;
    margin: 0px @base-margin;
}

.legend-container .ui.button{
    color: @main-white;
}

.legend-container .ui.button:hover{
    color: @basicButtonHoverFont;
}

.legend-container .ui.button:focus{
    color: @main-white;
}

.recharts-layer .recharts-brush-texts {
    font-family: @primary-font;
}

text, span {
    user-select: none
}

/* -------------------- FILTER DROPDOWN ---------------------- */


/* -------------------- SLIDER STYLES ----------------------- */
.slider-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: @base-padding*6;
}

.slider-container span {
    font-weight: bolder;
}

.rc-slider {
    margin: 0 @base-margin*4;
}

.rc-slider.rc-slider-disabled {
    opacity: 0.5;
    background: transparent;
}

.rc-slider-track.rc-slider-track-1 {
    cursor: col-resize;
}

.rc-slider-handle.rc-slider-handle-1 {
    cursor: col-resize;
}

.rc-slider-handle.rc-slider-handle-2 {
    cursor: col-resize;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: none !important;
    cursor: col-resize;
}

.handleDateTimeLabel {
    display: flex;
    flex-direction: column;
    font-family: @primary-font-bold;
    width: 110px;
    //text-align: center;
    background: transparent;
    letter-spacing: @primary-letter-spacing;
    &.left {
        align-items: flex-end;
    }
    &.right {
        align-items: flex-start;
    }
}

.chartFilterContainer .viewFilter > .ui.fluid.dropdown > .menu.transition.left.visible {
    min-width: 340px !important;
}

/* -------------------- VIEW ALERTS BUTTON ----------------------- */
.ui.button.viewAlertsButton {
    /* border-top-width: @primaryBorderWidth;
    border-right-width: @primaryBorderWidth;
    border-bottom-width: @primaryBorderWidth;
    border-left-width: 0; */
    border-style: solid;
    /* border-top-right-radius: @baseBorderRadius * 12;
    border-bottom-right-radius: @baseBorderRadius * 12; */
    border-width: @primaryBorderWidth;
    padding: @base-padding * 2 @base-padding * 6;
    font-size: @font-size-xs;
    margin: 0px;
}

.ui.dropdown.viewAlertsDropdown > .dropdown.icon {
    display: none;
}

.ui.dropdown.viewAlertsDropdown > .menu,
.ui.upward.dropdown.viewAlertsDropdown > .menu {
    width: 100%;
    margin-bottom: @base-margin * 2;
    border-radius: 11px;
    border-width: @primaryBorderWidth;
}

.ui.labeled.button.viewAlertsButton > div .menu {
    z-index: 100000;
}

.ui.labeled.button.viewAlertsButton > .ui.dropdown > .dropdown.icon {
    margin: 0em @base-margin 0em @base-margin;
} 

/* .legend-container > .legendBttn:hover {
    background-color: var(--hover-color) !important;
}

.legend-container > .legendBttn.inactive {
    background-color: var(--disabled-color) !important;
}

.barChartBar:hover path.barChartCell {
    fill: var(--hover-color) !important;
}
 */
.barChart.loading {
    opacity: .6;
    pointer-events: none;
}

.each(@i: 1) when (@i <= 14) {
    // selector based on href name
    .barCharCell@{i}:hover {
      opacity: .5
    }
    
    .each(@i + 1);
}

.bar-chart-container {
    .legend-container > .legendBttn {
        @defaultColor: var(--default);
        @legendHoverColor: var(--legendHover);

        &.inactive {
            background-color: transparent!important;
        }

        background-color: @defaultColor!important;
        
        &:hover {
            background-color: @legendHoverColor!important;
        }

        &.inactive {
            background-color: transparent!important;
        }
        
    }

    .barChart {
        .barChartCell {
            @defaultColor: var(--default);
            fill: @defaultColor;
            cursor: pointer;
        }
        &.hover {
            .barChartCell:not(.hover) {
                @hoverColor: var(--hover);
                fill: @hoverColor;
            }
        }

        .barChartBar.legendHover {
            .barChartCell {
                @legendHoverColor: var(--legendHover);
                fill: @legendHoverColor;
            }
        }
    }
}