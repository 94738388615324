@import "./fonts.less";
@import "./dataView.less";
@import "./site.less";
@import './dynamicForm.less';
@import "./variables.less";
@import "./aresModal.less";
@import "./contentHolder.less";
@import "./accountInfoForm.less";
@import "./carousel.less";
@import "./filterDropdown.less";
@import "./specialLabels.less";
@import "./aresRadio.less";
@import "./qrScanner.less";
@import "./dynamicList.less";
@import "./subsite.less";
@import "./reportCards.less";
@import "./timeSelection.less";
@import "./forecast.less";
@import "./exportSettings.less";
@import "./ratings.less";
@import "./pdfButton.less";
@import "./changePassword.less";
@import "./charts.less";
@import "./gauge.less";
@import "./bar.less";
@import "./monthlyReport.less";
@import "./responsePortal.less";
@import "./fillContainer.less";
@import "./heatmap.less";
@import "./themes.less";

::selection {
    background: @selectionRed; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: @selectionRed; /* Gecko Browsers */
}

html {
    font-smooth: never;
    height: 100%;
}


body {
    overflow: hidden;
    overscroll-behavior-x: none; 
}

.full {
    position: fixed;
    right: 0px;
    left: 0;
    top: 0px;
    bottom: 0px;
}

.glass:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    filter: saturate(110%) brightness(96%) blur(16px)
}

.glass {
    box-shadow: 0px 0px 24px 0px rgb(20 20 20 / 30%);
    z-index: 1;
    background: inherit;
    overflow: hidden;
}

#app {
    height: 100%;
    overflow: hidden;
}

.ui.breadcrumb .divider{
    color: @root-white
}

.imageHolder {
    width: 100%;
    height: 100%;
    background-color: gray;
    overflow: hidden;
    background: @bgImg no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

.imageHolder::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0px;
    left: -32px;
    right: 0px;
    bottom: 0px;
    filter: saturate(110%) brightness(90%) blur(16px);
}

.blurred::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0px;
    left: -32px;
    right: 0px;
    bottom: 0px;
    filter: saturate(110%) brightness(95%) blur(16px);
}

.blurred {
    background: inherit;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

/* Semantic ui customization */ 

.ui.borderLess.table tr td {
    border-top: 0px;
}

.ui.rootText {
    font-family: @primary-font-bold;
    letter-spacing: @primary-letter-spacing;
    color: @root-white
}

.ui.aboutGrid {
   width: auto;
   margin: auto auto;
   padding-right: @base-padding * 6;
}

.ui.fullSizedGrid {
    height: 100%;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.ui.glassSegment::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: -32px;
    left: -32px;
    right: -32px;
    bottom: -32px;
    filter: saturate(110%) brightness(90%) blur(16px);
}

.ui.glassSegment {
    border-radius: @baseBorderRadius*8;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    pointer-events: all;
    z-index: 1;
    background: inherit;
    backdrop-filter: saturate(110%) brightness(90%) blur(16px);
    -webkit-backdrop-filter: saturate(110%) brightness(90%) blur(16px);
    box-shadow: 0px 0px 24px 0px @glassSegmentBackground;
    padding: @base-padding*6 @base-padding*4;
}

@supports (-webkit-touch-callout: none) {
    // IOS
    .contentHolder { 
        background: unset;
    }
}

/* Root Styles */ 
.rootHeader {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.rootLogo {
    width: 80px;
    height: 80px;
    opacity: 0.9;
    fill: @root-white;
}

.rootHeaderText {
    position: relative;
    font-family: @primary-font-bold;
    letter-spacing: @primary-letter-spacing;
    color: @root-white;
    font-size: @font-size-xl*2;
    top: -14px;
    left: 4px;
}

.rootTitle {
    font-family: @primary-font-bold;
    font-size: @font-size-xl;
    margin-left: @base-margin * 4;
}

.horizontalDivider {
    width: 100%;
    height: 3px;
    background-color: @root-white;
    border-radius: @baseBorderRadius / 2;
    margin-top: @base-margin * 2;
    margin-bottom: @base-margin * 2;
}

.verticalDivider {
    width: 4px;
    height: 100%;
    background-color: @root-white;
    border-radius: @baseBorderRadius / 2;
    margin-left: @base-margin * 2;
    margin-right: @base-margin * 2;
}

.ui.loginHolder {
    padding-top: @base-padding * 2;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
}

.loginFooter {
    font-family: @primary-font-bold;
    font-size: @font-size-xs;
    letter-spacing: @primary-letter-spacing;
    color: @root-white;
}

.loginFooter > span {
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
}

.loginFooter > a {
    color: @root-white;
}

.loginFooter > a:hover {
    color: @main-white;
}
.loginHolder .ui.form {
    font-family: @primary-font-bold!important;
}

.loginHolder .ui.form input {
    letter-spacing: @primary-letter-spacing;
    font-size: @font-size-s!important;
    color: @root-white;
    text-align: left;
    font-weight: unset;
    background-color: @inputBackground !important;
    height: 48px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: (@base-padding * 4) + 2;
    padding-right: (@base-padding * 4) + 2;
    border-width: @primaryBorderWidth;
}

.loginHolder .ui.form input:-webkit-autofill::first-line {
    font-family: @primary-font-bold!important;
}

.loginHolder .ui.form input:hover {
    background-color: @inputHover !important;
}

.loginHolder .ui.form input::placeholder {
    opacity: .5;
}

.loginHolder .ui.form input::placeholder {
    color: @root-white;
}

.loginHolder .ui.form .loginField label {
    letter-spacing: @primary-letter-spacing;
    font-size: @font-size-xs;
    color: @root-white;
    text-align: left;
    font-weight: unset;
    padding-left: (@base-padding * 4) + 2;
    margin-top: (@base-margin) + 2;
    margin-bottom: (@base-margin) + 2;
}

.loginHolder .ui.inverted.button {
    font-family: @primary-font-bold;
    letter-spacing: @primary-letter-spacing;
    font-size: @font-size-m;
    margin-top: @base-margin * 3;
    padding-left: @base-padding * 12;
    padding-right: @base-padding * 12;
} 

.loginHolder .ui.inverted.button:hover {
    background-color: @basicButtonHover !important;
}

.loginHolder.registerSite .ui.form {
    .sectionHeader {
        display: flex;
        margin-bottom: @base-margin * 3;
        span {
            font-size: @font-size-m;
        }
    }
    .field {
        margin-bottom: @base-margin * 4;
        .fieldLabel {
            letter-spacing: @letterSpacing;
            font-size: @font-size-xs;
            padding-left: (@base-padding * 4) + 2;
        }
        .ui.input input {
            width: 244px;
            min-width: 244px;
        }
    }
    .field.error .ui.input button {
        border-color: @red;
    }
}

.registerSite hr {
    border-width: 1px;
    border-radius: @baseBorderRadius;
    border-style: solid;
    border-color: @root-white;
}

.loginHolder.registerSite .ui.form .dropdownField .ui.selection.dropdown {
    letter-spacing: @letterSpacing;
    font-size: @font-size-s !important;
    color: @root-white;
    text-align: left;
    font-weight: unset;
    background-color: @inputBackground !important;
    height: 48px;
    padding-top: (@base-padding * 2) + 2;
    padding-bottom: 0px;
    padding-left: (@base-padding * 4) + 2;
    padding-right: (@base-padding * 4) + 2;
    border-width: @primaryBorderWidth;
    input {
        padding-left: (@base-padding * 4) + 2;
    }
    .menu .selected.item {
        color: @white;
    }
    i {
        padding-top: (@base-padding * 2) + 2;
    }
    &:hover {
        background-color: @inputHover !important;
    }
    input:hover {
        background-color: unset !important;
    }
}

.ui.breadcrumb a {
    color: @root-white;
    position: relative;
    float: left;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    font-size: @font-size-2xl;
    top:4px
}

.ui.header {
    font-family: @primary-font-black;
    color: @root-white;
    letter-spacing: @letterSpacing;
}

.ui.header > .icon {
    display: table-cell;
    opacity: 1;
    font-size: inherit;
    padding-top: 0em;
    vertical-align: middle;
}

.ui.form .field.loginField > .ui.input {
    color: @root-white;
}

.ui.form .field.loginField > label {
    color: @main-white;
}

.ui.form .errorLabel {
    font-family: @primary-font-bold;
    display: inline-block;
    font-size: @font-size-2xs;
    margin-bottom: 4px;
    padding: 12px 12px;
    background-color: @errorLabelBackground;
    color: @red;
    border: @secondaryBorderWidth solid @red;
    border-radius: @baseBorderRadius * 3;
    letter-spacing: @primary-letter-spacing;
}

.ui.form .errorLabel > div {
    font-family: @primary-font-bold !important;
}

/******** main pages ********/
.pathBar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: @topBarHeight;
    border-style: solid;
    border-width: 0px 0px @primaryBorderWidth 0px;
    border-color: @root-white;
    background: inherit;
    z-index: 20;
}

.main {
    position: absolute;
    top: @topBarHeight;
    bottom: 0px;
    right: 0px;
    width: 100%;
    overflow: hidden;
}

.navigationMenu {
    position: absolute;
    width: @navBarWidth;
    left: 0;
    top: @topBarHeight;
    bottom: 0px;
    border-style: solid;
    border-width: 0px @primaryBorderWidth 0px 0px;
    border-color: @root-white;
    z-index: 100;
    transition: left @transitionNav, background-color .5s;
    background-color: rgba(0,0,0,0);
}


.navigationMenu.loading {
    left:-1*@navBarWidth
}

.pathHolder {
    width: 100%;
    height: 100%;
    background-color: @lightBlack;
    mix-blend-mode: hard-light;
}

.logoImage {
    float: left;
    opacity: 1.0;
    margin-top: @base-margin * 2;
    margin-left: @base-margin * 3;
    width: 40px;
}

.logoText {
    position: relative;
    float: left;
    top: 2px;
    left: 4px;
    font-family: @primary-font-black;
    letter-spacing: @letterSpacing;
    color: @root-white;
    font-size: @font-size-m * 2;
    line-height: normal;
}

.vSpacer {
    float: left;
    width: 4px;
    height: 40px;
    margin-right: @base-margin * 3;
    margin-left: @base-margin * 3;
    background-color: @main-white;
    border-style: solid;
    border-radius: @baseBorderRadius*2;
    border-width: 0px;
}

.pathText,
.pathTextMobile {
    float: left;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @main-white;
    font-size: @font-size-2xl;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.pathTextMobile {
    height: 100%;
}

.pathTextMobile a {
    color: @main-white;
}

.pathText a {
    height: 28px;
}

.pathText .ui.dropdown {
    margin-left: @base-margin;
}

.pathTextMobile {
    font-size: @font-size-m
}

.pathTextMobile {
    display: none
}

.pathTextMobile .ui.dropdown > i {
    display:none
}

.pathText i.icon {
    width: 12px;
    font-size: @font-size-xl;
}

.pathTextMobile i.icon {
    margin: 0em 0rem 0em 0em;
    width: fit-content;
    font-size: @font-size-xl;
    height: fit-content;
    display: block;
}

.pathTextMobile i.icon.caret.right:before {
    position: relative;
    left: 1px
}

.crumb {
    line-height: @font-size-2xl;
    color: @main-white;
}

.crumb > .caret {
    margin-left: @base-margin;
    margin-right: @base-margin;
}

/******** Content Area ********/

.contentHolder {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background: inherit;
}

.glassHolder {
    width: 100%;
    /* height: 100%; */
    margin: auto;
}

.contentHeader {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 42px;
    background-color: @pageBackground;
    color: @main-white;
    mix-blend-mode: hard-light;
    z-index: 1;
}

.contentScroll {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    bottom: 0px;
    overflow: auto;
}

.contentTransparent {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: @pageBackground;
    mix-blend-mode: hard-light;
    color: @main-white;
}

.contentSolid {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    color: @main-white;
}

/******* Loader *******/

/* Overlay */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black center center no-repeat;
    opacity: .5;
    z-index: 100;
}

/* Spinner */
.ui.loader.active {
    display:inline-block;
}

/***** DropDown Icon *****/
.dropdown.icon {
    padding-top: unset;
}

.dropdown.icon > i {
    padding-top: @base-padding;    
}

/****** Transparent Class With Background ******/
.transparent {
    background-color: @pageBackground;
    mix-blend-mode:hard-light;
    -webkit-mix-blend-mode: hard-light;
    -moz-mix-blend-mode: hard-light;
    -o-mix-blend-mode: hard-light;
    -ms-mix-blend-mode: hard-light;
    will-change: opacity;
}

/****** NonTransparent Class With Background ******/
.bg {
    background-color: @pageBackground;
}

/****** Page - Container ******/
.page-container {
    margin-left: @navBarWidth;
    height: 100%;
    transition: margin-left @transitionNav;
}

.page-container.loading {
    margin-left: 0px;
}

.page-container .page {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--primary-text-color);
}

.page-container > .page > .row,
.page-container > .page .dashboard.row,
.page-container > .page .ratings.row { 
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-container > .page .dashboard.row.right {
    width: 30%;
}

.page-container .page > .row.middle,
.page-container .page .row.middle {
    height: inherit;
}

/********** Page - Header *********/
.pageHeader {
    overflow-y: hidden;
    overflow-x: auto;
    height: @pageHeaderHeight;
    letter-spacing: @letterSpacing;
    display: flex;
    align-items: center;
}

.pageHeader h1.ui.header a {
    color: @root-white;
}

.pageHeader h1.ui.header {
    white-space: nowrap;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: @base-margin * 4;
    font-size: @font-size-xl;
    letter-spacing: @letterSpacing;
    font-family: @primary-font-bold;
}

.pageHeader h1 > i:first-child {
    display: inline-block;
    width: 26px;
    margin-right: @base-margin * 2 + 2;
}

.pageHeader h1 > .icon-monitor4 {
    font-size: @font-size-xl
}

/******** Page - Decorations ********/
.navMenu-header {
    margin-left: @base-margin*2;
    font-family: @secondary-font;
    color: rgba(255, 255, 255, 0.9);
    font-size: 40px;
    float: left;
    display: flex;
    align-items: center;
    height: 100%;
}

.navMenu-menuBtn {
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    padding: @base-padding*2;
    display: none;
    cursor: pointer;
}

.navMenu-menuBtn:hover {
    color: fade(rgba(255, 255, 255, 0.9), 50%);
}

.navMenu-title {
    position: relative;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: rgba(255, 255, 255, 0.9);
    font-size: 40px;
    line-height: normal;
    left: 4px;
    top: 2px;
    height: 40px;
}

.navMenu-logo
{
    border-style: none;
    float: left;
    margin-left: @base-margin;
}

.navMenu-logo .logo{
    fill: @root-white
}

/******* Navigation - Nav Menu Item ********/
.navMenuItem-grid {
    display: flex;
    flex-direction: column;
}

.navMenu-item {
    line-height: initial;
    background-color: transparent;
    transition: 0.5s;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @main-white;
    font-size: @font-size-xl;
    padding-left: @base-padding * 4;
    padding-top: @base-padding;
    padding-bottom: @base-padding;
    margin-right: @base-margin * 2;
    margin-top: @base-margin * 3;
    border-radius: 0px @baseBorderRadius*6 @baseBorderRadius*6 0px;
}

.navMenu-item:hover {
    mix-blend-mode: hard-light;
    background: inherit;
    opacity: 1;
    color: @root-white;
    background-color: @navHover;
}

.navMenu-item.active { 
    background-color: @navActive;
}

.navMenu-item > i {
    margin-right: @base-margin;
    width: 28px;
    text-align: center;
}

/******* Main Header Bar ********/
.mainHeaderBar {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    height: @topBarHeight;
    border-style: solid;
    border-width: 0px 0px @primaryBorderWidth 0px;
    border-color: @root-white;
    background: inherit;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1000000002;
}

.mainHeaderBarLeft {
    position: relative;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
}

.mainHeaderBarMiddle {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mainHeaderBarRight {
    position: relative;
    height: 100%;
    width: fit-content;
    right: 0px;
    display: flex;
    align-items: center;
    padding-right: 12px;
}

.mainHeaderBar .dropdown.icon > .dropdown.icon {
    display: none;
}

.ui.dropdown.settings {
    position: initial;
    float: right;
}

.settingsMenu {
    float: right;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @root-white;
    font-size: @font-size-xs;
    padding: @base-padding @base-padding * 3;
    height: 34px;
    border-radius: @baseBorderRadius * 6 + 2;
    border: @primaryBorderWidth solid @root-white;
    background-color: @settingsButton;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 146.98px;
}

.settingsMenu > i {
    height: 100%;
}

.settingsMenu:hover {
    cursor: pointer;
    background-color: @settingsButtonHover;
}

.ui.dropdown .menu.settingsMenu {
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    top: @topBarHeight - 10;
    z-index: 1000000000000;
}

.ui.dropdown .menu.settingsMenu > .item {
    padding: ((@base-padding * 2)) ((@base-padding * 2) + 2)!important;
}

.blurred::before > div {
    background: inherit;
}

/***** Settings *****/
.accountInfoHolder {
    position: absolute;
    left: 24px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding-bottom: @base-margin * 3;
    overflow: auto;
    pointer-events: all;
}

.accountInfoForm {
    padding-top: @base-padding * 2;
    color: @main-white;
}

body ::-webkit-scrollbar-track {
    border-radius: 5px;
}

.ui.loader:after {
    border-color: @root-white transparent transparent;
}

* {
    /* ScrollBar */
    &::-webkit-scrollbar {
        width: 3px!important;
        height: 3px!important;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: @slateGrey;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {     
        background: @root-white!important;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: @root-white!important;
    }
}

.flexcenter {
    display: flex;
    justify-content: center;
    align-items:center;
}

.ui.loader:before {
    border-width: .4em;
}

.ui.loader:after {
    border-width: .4em;
}

.info.middle {
    height: calc(100% - 269px)
}

.notfound {
    font-size: @font-size-xl*2;
}

.systemMessage {
    margin-top: @base-margin * 2;
    font-family: @secondary-font;
    letter-spacing: 0px;
    font-size: @font-size-s;
    padding: @base-padding;
    border: solid;
    border-width: @primaryBorderWidth;
    border-radius: @baseBorderRadius * 5;
    margin-bottom: @base-margin * 2;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.systemMessage.critical {
    color: @red;
    border-color: @red;
}

.systemMessage.major {
    color: @yellow;
    border-color: @yellow;
}

.systemMessage.info {
    color: @green;
    border-color: @green;
}

.systemMessage i {
    font-size: @font-size-l;
}

.systemMessage td:last-child {
    color: @main-white;
    font-size: @font-size-xs;
}

.fas .fa-sync .refresh {
    cursor: pointer;
}

.refreshButton:hover {
    height: 100%;
    cursor: pointer;
    transform-origin: center center;
    transition: 10s transform;
    transform: rotate(180deg);
}

.sectionTitle, .refreshStatus {
    color: @root-white;
}

.liveDataBox {
    min-width: 338px;
    width: 32%
}

.errorMessage {
    color: @red;
}

.ui.grid.liveSensorGrid {
    margin-top: 0; 
    margin-bottom: 0; 
    margin-left: 0;
    margin-right: 0;
}

.liveSensorGrid .five.column.row {
    padding-top: 0px;
    padding-bottom: 0px
}

.two { 
    grid-area: two; 
}

.autoresponsive-item {
    display: flex;
    justify-content: center;
    height: 205px;
} 

.gridContainer.visible.transition {
    display: flex !important;
    width: 100%;
    flex-wrap: wrap;
    margin: 2% 5%
}

* {
&::-webkit-autofill::first-line,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        font-family: @secondary-font !important;
      }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
    font-family: @secondary-font!important;
    transition: background-color 5000s!important;
}

.ui.form .field.loginField > .ui.input > input:-webkit-autofill,
.ui.form .field.loginField > .ui.input > input:-webkit-autofill:hover,
.ui.form .field.loginField > .ui.input > input:-webkit-autofill:focus,
.ui.form .field.loginField > .ui.input > input:-webkit-autofill:active,
.ui.form .field.loginField > .ui.input > input:-webkit-autofill::first-line {
    -webkit-text-fill-color: @root-white !important;
    border-color: @root-white !important;
}

.ui.button.cancel {
    background: @lightRed
}

.ui.button.cancel:hover {
    background: darken(@lightRed, 5%);
}

.ui.button.save {
    background: @green
}

.ui.button.save:hover {
    background: darken(@green, 10%);
}

.ui.button.apply {
    background: @lightBlue;
}

.ui.button.apply:hover {
    background: darken(@lightBlue, 5%);
}

.ui.button.reset {
    background: @lightRed
}

.ui.button.reset:hover {
    background: darken(@lightRed, 5%);
}

.ui.button.aresButton {
    border: @primaryBorder;
    white-space: nowrap;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
    width: 100%;
    margin: 0px;
}

.ui.button.aresButton {
    background-color: @opaqueBlack;
    color: @main-white
}

.ui.button.aresButton.green {
    background: @green
}

.ui.button.aresButton.green:hover {
    background: darken(@green, 10%)
}

.ui.button.aresButton.purple {
    background: @purple
}

.ui.button.aresButton.purple:hover {
    background: darken(@purple, 5%)
}

br {
    display: block;
    content: "";
    margin-bottom: @base-margin * 4;
}

.changed,
.changed input {
    border-color: @basketBallOrange!important
}

.changedIcon {
    font-size: @font-size-3xs / 2; 
    vertical-align: 2px; 
    margin-left: @base-margin;
    margin-right: @base-margin;
    color: @basketBallOrange
}

.errorIcon {
    font-size: @font-size-3xs; 
    vertical-align: 0px; 
    margin-left: @base-margin;
    color: @darkRed;
}

.errorText {
    color: @darkRed
}

.goodIcon {
    font-size: @font-size-3xs; 
    vertical-align: 0px; 
    margin-left: @base-margin;
    color: @green;
}

.aresFormGroup > .field .errorIcon {
    margin-bottom: @base-margin / 2;
}

.aresFormGroup > .field .changedIcon {
    margin-bottom: @base-margin / 2;
}

.aresFormGroup > .field .aresRadio .changedIcon {
    margin-bottom: -1 * (@base-margin / 2);
}

.accordionTitle {
    display: flex;
    align-items: flex-start;
    align-items: center;
}

.accordionTitle > .text {
    padding: 0px;
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.accordionView {
    position: relative;
    z-index: 1000;
}

.accordionView.active {
    max-height: 100vh;
    transition: max-height .5s, clip-path .5s;
    clip-path: inset(0px 0px -300px 0px);
}

.accordionView.inactive {
    max-height: 0;
    transition: max-height .2s, clip-path .2s;
    clip-path: inset(0px 0px 0px 0px)
}

/* Scroll View */
.scroller {
    height: 100%;
    overflow-y: auto;
}

.scrollController {
    height: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.scrollController > .scrollView { 
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.pdfButtonContainer {
    &.report {
        font-size: @font-size-xs;
        cursor: pointer;
        pointer-events: all;
        z-index: 1;
    }
    &.barchart {
        position: relative;
        float: right;
        font-size: @font-size-4xl;
        cursor: pointer;
        pointer-events: all;
        z-index: 0;
        width: 100%;
        padding-right: @base-padding * 20;
        padding-left: @base-padding * 25;
        margin-bottom: @base-margin * 4;
    }
    &.linechart {
        position: relative; 
        float: right; 
        font-size: @font-size-4xl; 
        cursor: pointer;
        pointer-events: all;
        z-index: 1;
        margin-right: @base-margin * 9;
        margin-bottom: @base-margin * 4;
    }
}

.viewer {
    height: 100%;
    width: 100%;
}

#toolTipsApp {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100000000000000;
    pointer-events: none;
}

.blurring.dimmed.dimmable > #toolTipsApp {
    filter: blur(0px);
}

.aresPopUp {
    position: absolute;
    padding: @base-padding * 3;
    border-radius: @baseBorderRadius * 6;
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    font-size: @font-size-3xs;
    border: @primaryBorder;
    border-radius: @baseBorderRadius * 3;
    font-family: @secondary-font-black;
    color: @root-white;
    background-color: @opaqueBlack;
    max-width: 80vw;
    overflow-x: auto;
}

.aresPopUp.active {
    transition: opacity .3s ease-in;
    visibility: visible;
    opacity: 1;
}

.ui.button.refreshBttn {
    background-color: @yellow;
    width: 100%;
    padding: @base-padding*3 0px; 
    margin-bottom: @base-margin*2;
}

.ui.button.refreshBttn:hover {
    background-color: fade(@yellow, 80%);
}

.faOff::before {
    opacity: .6;
}

.faOff::after {
    content: '\f715';
    font-size: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    width: inherit;
}

.registerSiteMessage {
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
    padding-bottom: @base-padding * 2;
    font-size: @font-size-s;
}

.wizardFormContent .ui.steps {
    border-radius: @baseBorderRadius * 12;
    border: @secondaryBorderWidth solid fade(@settingsGray, 100%);
    @media (max-width: 475px) {
        /* CSS that should be displayed if width is equal to or less than 400px goes here */
        .step {
            width: 98px;
            i {
                margin: 0;
            }
        }
    }
    .step {
        padding: @base-padding * 4 @base-padding * 5;
        border-color: fade(@settingsGray, 100%);
        background-color: fade(@main-white, 100%);
        &:after {
            border-color: fade(@settingsGray, 100%);
            background-color: fade(@main-white, 100%);
        }
        i {
            font-size: @font-size-m;
        }
        .title {
            font-size: @font-size-2xs;
            color: fade(@settingsGray, 100%);
            letter-spacing: @letterSpacing;
        }
        &:first-child {
            border-radius: @baseBorderRadius * 12 0 0 @baseBorderRadius * 12;
        }
        &:last-child {
            border-radius: 0 @baseBorderRadius * 12 @baseBorderRadius * 12 0;
        }
    }
}

.wizardFormContent .formActions {
    width: 100%;
    .wizardBttn {
        padding: @base-padding * 3 @base-padding * 5;
        border: @primaryBorderWidth solid @white;
        &.back {
            float: left;
        }
        &.next {
            float: right;
        }
        &.submit {
            float: right;
        }
    }
}

.deviceStrikethrough {
    display: flex;
}

.fa-stack.xs {
    font-size: @font-size-xs;
}

.statusTrue .fa-solid.fa-envelope {
    font-size: @font-size-s;
}

.webSocketNotification {
    border-radius: 50%;
    background-color: @red;
    box-shadow: 0 0 8px -2px @red;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: -30px;
    border-width: 0px;
}

.webSocketNotification.menuBtnNotification {
    border-radius: 50%;
    background-color: @red;
    box-shadow: 0 0 8px -2px @red;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 30px;
    border-width: 0px;
}

.webSocketNotification span {
    font-size: @font-size-s;
}

.pdfPage {
    pointer-events: all;
    height: 792pt;
    width: 612pt; 
    padding: @base-padding*5 @base-padding*5;
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: @base-margin*4;
}

.starsContainer {
    height: inherit;
    display: flex;
    justify-content: center;
    letter-spacing: 2px;
    &.ratingPageStars {
        border-radius: @baseBorderRadius * 12;
        border: @primaryBorderWidth solid @white;
        padding: @base-padding * 2;
        background: @grey;
        color: @white;
    }
}

.star {
    display: flex;
    align-items: center;
}

.ui.button.applyReportDate {
    font-size: @font-size-xs;
    padding: @base-padding * 2 @base-padding * 4;
    margin-left: @base-margin * 2;
    color: @darkGrey;
    border: @primaryBorderWidth @darkGrey solid;    
}

.chartContainer.pdfPages {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    min-width: fit-content;
    position: relative;
    padding-top: @base-padding * 4;
    //background-color: rgba(20,20,20,0.2);
}

/*********************** Reports ********************************/
.reports-card-container {
    height: fit-content;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    pointer-events: all;
    position: relative;
    padding-left: @base*5;
    padding-right: @base*5;
    padding-bottom: @base*4;
    pointer-events: all;
    align-content: flex-start;
}

/*********************** Calendar ********************************/

.aresCalendar {
    position: absolute;
    margin-top: 0px;
    bottom: 38px;
    width: 100%;
    height: fit-content;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 3;
    z-index: 1000000;
    cursor: default;
}
  
.aresCalendar > .calendarTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: @tableBackground;
    color: @root-white;
    height: 32px;
}

.aresCalendar > .calendarTitle > .title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @tableBackground;
    cursor: pointer;
}
  
.aresCalendar > .calendarTitle .calendarTitleButtons {
    display: flex;
    height: 100%;
    cursor: pointer;
}

.aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav {
    background-color: @tableBackground;
    line-height: @font-size-2xs;
    font-size: @font-size-2xs;
    display: flex;
    align-items: center;
}

.aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav[disabled] {
    background-color: grey;
    cursor: default;
    pointer-events: none;
}

.aresCalendar .yearGrid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.aresCalendar .yearGrid .year {
    background-color: @tableBackground;
    padding: @base-padding*2 @base-padding*3;
    cursor: pointer;
}

.aresCalendar .calendarTitle >.title:hover {
    background-color: @blue;
}

.aresCalendar .yearGrid .year.selected {
    background-color: @green;
}

.aresCalendar .yearGrid > .year[disabled] {
    color: @root-white;
    background-color: grey;
    cursor: default;
    pointer-events: none;
}

.aresCalendar .yearGrid > .year:nth-child(10) {
    border-bottom-left-radius: (@baseBorderRadius * 2) + 2;
}
.aresCalendar .yearGrid > .year:nth-child(12) {
    border-bottom-right-radius: (@baseBorderRadius * 2) + 2;
}

.aresCalendar .monthGrid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.aresCalendar .monthGrid .month {
    background-color: @tableBackground;
    padding: @base-padding*2 @base-padding*3;
    cursor: pointer;
}

.aresCalendar .calendarTitle >.title:hover {
    background-color: @blue;
}

.aresCalendar .monthGrid .month.selected {
    background-color: @green;
}

.aresCalendar .monthGrid > .month[disabled] {
    color: @root-white;
    background-color: grey;
    cursor: default;
    pointer-events: none;
}

.aresCalendar .monthGrid > .month:nth-child(10) {
    border-bottom-left-radius: (@baseBorderRadius * 2) + 2;
}

.aresCalendar .monthGrid > .month:nth-child(12) {
    border-bottom-right-radius: (@baseBorderRadius * 2) + 2;
}

.aresCalendar .dayGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
}

.aresCalendar .dayGrid .day {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    height: 29px;
    background-color: @tableBackground;
    cursor: pointer;
}

.aresCalendar .dayGrid .day.outOfRange,
.aresCalendar .dayGrid .day.outOfRange:hover {
    background-color: lighten(@tableBackground, 30%);
    cursor: default!important;
    pointer-events: none;
}

.aresCalendar .dayGrid .day.outOfRange.clickable {
    cursor: pointer!important;
    pointer-events: all;
}

.aresCalendar .dayGrid > .day:hover {
    background-color: @green;
    color: @root-white;
}

.aresCalendar .dayGrid > .day[disabled],
.aresCalendar .dayGrid .day.hover[disabled] {
    color: @root-white;
    background-color: grey;
    cursor: default;
    pointer-events: none;
}

.aresCalendar .dayGrid > .day.selected {
    background-color: @green;
}

.aresCalendar .dayGrid .day.hover {
    background-color: darken(@green, 30%); 
}

.aresCalendar .dayGrid > .day.betweenSelected {
    background-color: lighten(@green, 10%);
}

.aresCalendar .dayGrid > .day.otherSelected {
    background-color: @green;
}

.aresCalendar .dayGrid > .day:nth-child(43) {
    border-bottom-left-radius: (@baseBorderRadius * 2) + 2;
}
.aresCalendar .dayGrid > .day:nth-child(49) {
    border-bottom-right-radius: (@baseBorderRadius * 2) + 2;
}

.aresCalendar .dayGrid > .day.dayOfWeek,
.aresCalendar .dayGrid > .day.dayOfWeek:hover {
    cursor: default
}

.calendarPicker {
    border: @secondaryBorder;
    display: flex;
    border-radius: @baseBorderRadius * 12;
    justify-content: flex-start;
    height: 42px;
    width: 100%;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 7;
    align-content: center;
    align-items: center;
    position: relative;
    font-family: inherit;
    letter-spacing: inherit;
}

.calendarPicker input,
.ui.modal.aresModal .ui.selection.dropdown.picker,
.ui.selection.dropdown.picker,
.ui.form .calendarPicker input.picker[type="text"],
.ui.form .calendarPicker select.picker {
    border: 0px;
    text-align: center;
    padding: 0px;
}

.ui.form .calendarPicker input[type="text"],
.ui.form .calendarPicker select.picker,
.ui.form .calendarPicker input.picker[type="text"]:focus,
.ui.form .calendarPicker select.picker:focus {
    font-size: inherit;
    border-radius: 0px!important;
    padding: 0px;
}

.picker,
.ui.form input.picker[type="text"],
.ui.form select.picker,
.ui.modal.aresModal .ui.selection.dropdown.picker {
    background: transparent;
    color: @root-white;
    font-family: inherit;
    min-width: auto;
}

.ui.form .calendarPicker .input,
.textarea {
    font-family: inherit;
    font-size: inherit;
    padding: @base-padding - 3 @base-padding + 2;
}

.ui.form .select.picker:after { 
    background: black;
    color: white
}

/* .ui.form .customSelector select.picker {
    display: none;
} */

.ui.selection.dropdown.picker {
    margin-left: @base-margin;
}

.ui.selection.dropdown.picker .menu > .item {
    padding: 0px!important;
    display: flex;
    justify-content: center;
    padding-top: @base-padding!important;
    padding-bottom: @base-padding!important;
}

.ui.selection.dropdown.picker {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.ui.modal.aresModal .ui.dropdown.picker > .divider.text {
    padding: 0;
    width: fit-content;
}

.ui.modal.aresModal .ui.selection.dropdown.picker > .dropdown.icon {
    position: relative;
    float: left;
    display: block;
    right: unset;
    margin: 0px;
    padding: 0px;
    margin-left: @base-margin;
    margin-right: @base-margin;
    top: 0px;
}

.ui.modal.aresModal .ui.selection.dropdown.picker .visible.menu {
    border-top-width: @secondaryBorderWidth!important;
    border-radius: @baseBorderRadius * 2;
    overflow: hidden;
}

.ui.modal.aresModal .ui.selection.dropdown.picker {
    height: unset;
}

.ui.form .ui.selection.dropdown.picker.periodPicker {
    margin: 0px;
    padding: 0px;
}

.calendarPicker .calendarClear {
    position: absolute; 
    right: 16px;
    cursor: pointer;
}

/***********************************Calendar*************************************/
.react-datetimerange-picker.react-datetimerange-picker--closed.react-datetimerange-picker--enabled.aresSelect,
.react-daterange-picker.react-daterange-picker--enabled.aresSelect,
.react-daterange-picker.react-daterange-picker--enabled.aresSelect{
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
    height: 44px;
    width: 100%
}

.aresSelect .react-datetimerange-picker__inputGroup__input,
.aresSelect .react-daterange-picker__inputGroup input {
    color:@root-white!important;
    outline: 0px;
    border: 0px;
}

.aresSelect .react-daterange-picker__wrapper,
.aresSelect .react-datetimerange-picker__wrapper {
    border: none;
    padding-left: @base-padding * 3;
    padding-right: @base-padding * 3;
}

.react-datetimerange-picker__clear-button__icon.react-datetimerange-picker__button__icon,
.react-datetimerange-picker__calendar-button__icon.react-datetimerange-picker__button__icon {
    stroke: @root-white
}

.react-daterange-picker__clear-button.react-daterange-picker__button {
    position: absolute;
    right: 5px;
}

.react-datetimerange-picker__clear-button.react-datetimerange-picker__button,
.react-datetimerange-picker__calendar-button.react-datetimerange-picker__button, 
.react-daterange-picker__calendar-button.react-daterange-picker__button
{
    display: none;
}


.react-daterange-picker__clear-button.react-daterange-picker__button svg {
    stroke: @root-white;
}

.aresSelect .react-daterange-picker__calendar {
    z-index: 10000;
    width: 100%!important;
}

.aresSelect .react-calendar {
    background: black;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
    padding: 0px;
    position: relative;
    left: 0px;
    width: 100%
}

.aresSelect .react-calendar__tile.react-calendar__month-view__days__day {
    color: @root-white;
}

.aresSelect .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    color: @root-white;
}

.aresSelect .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
    color: black;
}

.aresSelect .react-calendar__navigation button {
    color: @root-white;
}

.aresSelect .react-calendar__navigation button {
    min-width: 25px;
}

.react-calendar__navigation button[disabled]:last-child{
    border-radius: 0px 24px 0px 0px;
}

.aresSelect .react-calendar__tile.react-calendar__month-view__days__day:last-child {
    border-radius: 0px 0px 24px 0px;
}
.aresSelect .react-calendar__tile.react-calendar__month-view__days__day:nth-last-child(7) {
    border-radius: 0px 0px 0px 24px;
}

.react-clock__face {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: @secondaryBorderWidth - 1 solid @root-white;
    border-radius: 50%;
}

.react-clock__hand__body {
    background-color: @root-white
}

.aresSelect .react-datetimerange-picker__clock {
    background-color:black;
    z-index: 1000;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
}

.react-datetimerange-picker__calendar.react-datetimerange-picker__calendar--open {
    width: 235px !important;   
    z-index: 100;
}

.react-clock__mark__body {
    color: @root-white;
}

.react-datetimerange-picker.react-datetimerange-picker--closed.react-datetimerange-picker--enabled.aresSelect.fit {
    height: 100%;
}

.fit .react-datetimerange-picker__wrapper {
    flex-direction: column;
} 

// OVERRIDE
.ui.form .react-datetimerange-picker select {
    display: revert;
    height: 40px;
    width: 40px;
    padding: 0px;
    background: transparent;
    border: none;
    border-radius: 0px;
    font-size: @font-size-2xs - 2px;
    /* box-shadow: 0em 0em 0em 0em transparent inset; */
    /* padding: 0.62em 1em; */
    /* color: rgba(0, 0, 0, 0.87); */
    /* transition: color 0.1s ease, border-color 0.1s ease; */
}

.ui.form .react-datetimerange-picker input,
.ui.form .react-datetimerange-picker input[type="number"] {
    padding: 0px;
    border: 0px;
}

.react-datetimerange-picker, .react-datetimerange-picker *, .react-datetimerange-picker *:before, .react-datetimerange-picker *:after {
    color: @root-white
}

// Calendar buttons
.react-calendar__tile--active {
    background-color: @blue;
}

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus {
    background: fade(@blue, 80%);
}

.react-calendar__tile:enabled:hover, 
.react-calendar__tile:enabled:focus {
    background: fade(@root-white, 30%)
}

.react-calendar--selectRange .react-calendar__tile--hover,
.react-calendar--selectRange .react-calendar__tile--hover {
    background: fade(@root-white, 40%)
}

.aresSelect .react-calendar__navigation button[disabled],
.aresSelect .react-calendar__tile:disabled {
    background-color: @darkRed;
}

.react-calendar__tile--now {
    background-color: @yellow;
}

/******************************************Mobile Rating Toggle********************************************/
.mobileRatingToggle {
    padding-top: @base-padding;
    padding-bottom: @base-padding * 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: @main-white;
    font-size: @font-size-xs;

}

.mobileRatingToggle i {
    margin-left: @base-margin*2;
    margin-right: @base-margin*2;
    padding-bottom: @base-margin;
}

.mobileRatingToggle i.active {
    color: fade(@main-white, 50%);
    transition: border .5s, padding-bottom .5s;
    padding-bottom: @base-margin/2;
    border-bottom: @secondaryBorderWidth solid fade(@main-white, 50%);
}

.mobileRatingToggle i:hover {
    cursor: pointer;
}

.mobileRatingToggle i {
    font-size: @font-size-xs;
}

.mobileRatingToggle i > span {
    font-family: @primary-font-bold;
    margin-left: @base-margin*2;
    font-size: @font-size-xs;
}

.mobileRatingToggle.ratingsPageToggle {
    padding-top: @base-padding * 4;
}

/*****************************Mobile Info Cards********************************/
.mobileInfoCards {
    display: none;
}

.mobileInfoCards .cardBox.infoCard {
    width: 100%;
}
/*******************************************************************************/

.authOption > .authStatus {
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 4;
    padding: @base-padding / 2;
    font-size: @font-size-3xs;
    margin-left: @base-margin;
}

.authStatus.good {
    background-color: @green;
}

.ui.button.authMethodButton {
    font-size: @font-size-2xs!important;
    border: @secondaryBorder;
    box-shadow: none!important;
    padding: 0px @base-padding * 4 !important;
    height: 32px;
    background: transparent;
}

.authMethodField {
    display: flex;
    gap: @base-margin
}

.authMethodField > .field {
    width: 100%
}