@import "./variables.less";

.reportContainer {
    position: relative;
    width: 612pt;
    height: 100%;
    margin: 0 @base-margin*6;
}

.printView {
    width: 612pt;
    margin: 0 auto;
}

.reportPage {
    pointer-events: all;
    height: 792pt;
    width: 612pt; 
    padding: @base-padding*5 @base-padding*5;
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: @base-margin*4;
}

.footerContainer {
    color: black!important;
    position: relative;
    display: flex;
    height: inherit;
    align-items: flex-end;
    margin-bottom: @base-margin * 2;
}

.pieChartTitle {
    font-size: @font-size-xs;
    font-weight: bold;
    color: @black;
}

.reportTitleContainer {
    background: @reportTitle;
    text-align: center;
    padding: 10px 0px;
    color: @white;
    .siteName {
        font-size: @font-size-4xl;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .reportTitle {
        font-size: @font-size-5xl;
        font-weight: bold;
        line-height: normal;
    }
}

.reportSectionHeader {
    font-size: @font-size-2xl;
    background: @reportSectionHeader;
    color: @white;
    text-align: center;
    margin-bottom: @base-margin * 2;
    padding: 10px 0;
    line-height: normal;
}

.headerInfo {
    font-size: @font-size-xs;
    margin-bottom: @base-margin * 3;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    color: @black;
    .sectionContainer {
        display:flex;
        height: fit-content;
        justify-content: space-between;
        margin-bottom: @base-margin;
        .sectionInfo {
            strong {
                font-size: @font-size-xs;
            }
            font-size: @font-size-3xs;
            margin: 10 0px;
            width: 45%;
        }
    }
}


.helpfulTips {
    display: flex;
    flex-direction: column;
    color: @black;
    border: 1px solid @black;
    width: fit-content;
    padding: @base-padding * 4;
    margin-top: @base-margin * 4;
    border-radius: @base * 5;
    font-size: @font-size-xs;
    height: fit-content;
}

.helpfulTipsTitle {
    font-weight: bold;
}

.ratingCardContainer {
    display: flex;
    justify-content: space-evenly;
    color: white;
}

.ratingCard {
    border-radius: @base * 7;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 200px;
    height: 150px;
    border: 1px solid @black;
}

.ratingCardHeader {
    font-size: @font-size-2xl;
    text-align: center;
}

.ratingCardScore {
    font-size: @font-size-4xl;
    font-weight: bold;
    text-align: center;
}

.rankTableContainer {
    background: @reportTable;
    width: 325px; 
    max-width: 325px;
    height: fit-content;
    padding: @base-padding * 4;
    border-radius: @base * 5;
    margin: @base-margin * 2;
    text-align: center;
    .rankTableTitle {
        font-size: @font-size-l;
        color: @white;
        margin-bottom: @base-margin;
        font-weight: bold;
    }
    
    .rankTable {
        width: 100%;
        border-collapse: collapse;

        th {
            padding: @base-margin*2 0px;
            height: auto;
            width: auto;
            font-size: @font-size-xs;
        }
        tr {
            border-bottom: 1px solid @lightGrey;
        }
        tr:last-child {
            border-bottom: none;
        }
        td {
            text-align: center;
        }
    }
}

.chartTitle {
    text-align: center;
    font-size: @font-size-xs;
    font-weight: bold;
}

.scroller.monthlyReport { 
    overflow-x: auto;
}

.scrollView.monthlyReport {
    width: 100%;
    justify-content: unset;
    flex-wrap: wrap;
    flex-direction: column;
}

.summaryTable {
    margin-top: @base-margin * 2;
}

.dynamicDeviceText {
    font-family: @primary-font-black;
}

.textStrikethrough {
    text-decoration: line-through;
}

.reportTopBar {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 0;
    left: 0;
    padding-bottom: @base-padding * 2;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
}

.reportLine {
    height: @base;
    border-radius: @baseBorderRadius;
    background-color: grey;
    margin-left: @base-margin * 2;
    margin-right: @base-margin * 2;
}

.pdfButtonContainer.report .ui.button.pdfButton {
    border-radius: @baseBorderRadius * 6;
    padding-top: @base-padding * 2;
    padding-bottom: @base-padding * 2;
    padding-left: @base-padding * 6;
    padding-left: @base-padding * 6;
}

.reportScrollContainer {
    height: 100%;
    width: 100%;
    padding: 0 @base-padding * 2;
}
