.ui.scrolling.dropdown .menu {
    min-width: unset!important;
}

.ui.selection.dropdown > .dropdown.icon {
  top: @inputLineHeight / 2;
}

.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
  content: "\f0d7";
}

.ui.dropdown .menu  {
  overflow: hidden;
}

.ui.selection.dropdown .menu {
  left: -1px;
}