@import "./variables.less";

/***** Relative Content *****/
.contentHolder.relativeContentHolder {
    position: relative;
    height: 100%;
}

.contentHolder.relativeContentHolder .contentHeader {
    background: none;
}

.contentHolder.relativeContentHolder .contentHeader .pageHeader {
    height: 42px;
}

.contentHolder {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: inherit;
    overflow: auto;
    //background-attachment: scroll;
}

.contentHolder .flex {
    display: flex;
}