@import './variables.less';

@barWidth: 16;

.bars.unavailable {
    filter: grayscale(1);
}

.bars.unavailable .barsTitle {
    opacity: .5;
}

.bars.unavailable .bar {
    opacity: .2;
}

.barsTitle {
    font-size: @font-size-m;
    font-family: @secondary-font-bold;
    display: flex;
    justify-content: center;
}

.bar {
    height: @barWidth + 4px;
}

.barBackground,
.barFill {
    fill: none;
    stroke-width: @barWidth;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.barGlow {
    fill: none;
    stroke-width: @barWidth;
    stroke-linecap: round;
    filter: url(#red-glowing);
}

filter#red-glowing > feFlood {
    flood-color: rgba(224, 97, 103, 1);
    animation: flashing 1s infinite;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-out
}

.barFill {
    fill: none;
    stroke-linecap: round;
}

.barTitle,
.barValue {
    font-size: @font-size-xs;
    font-family: @secondary-font-bold;
}

.barSymbol {
    font-size: @font-size-3xs;
}

/** Color **/
.barBackground {
    stroke: fade(@darkGrey, 20%);
}

.barTitle,
.barValue {
    fill: @root-white
}

.barsTitle {
    color: @root-white;
}