@import './variables.less';

.timeSelection {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ui.form .timeSelection .fields,
.ui.timeSelection .fields,
.timeSelection > .fields {
    width: fit-content;
    border-radius: @baseBorderRadius * 6;
    border: @secondaryBorderWidth solid @root-white;
    padding-left: @base-padding;
    padding-right: @base-padding;
    align-items: center;
    display: flex;
    flex-direction: row;
    align-content: center;
}

.ui.form .timeSelection .fields .field.timeField,
.ui .timeSelection .fields .field.timeField,
.timeSelection .fields .field.timeField {
    padding: 0;
    padding-left: @base-padding;
    padding-right: @base-padding;
}

.ui.form .timeSelection .fields > .field.timeField:first-child,
.ui .timeSelection .fields > .field.timeField:first-child,
.timeSelection .fields > .field.timeField:first-child {
    padding-left: @base-padding * 2;
}

.ui.form .timeSelection > .fields .field.timeField:last-child,
.ui .timeSelection .fields > .field.timeField:last-child,
.timeSelection .fields > .field.timeField:last-child  {
    padding-right: @base-padding * 2;
}

.ui.form .timeSelection .ui.selection.dropdown,
.ui .timeSelection .ui.selection.dropdown,
.timeSelection .ui.selection.dropdown {
    border: none;
    min-width: 0px;
    padding: 0;
    display: flex;
    align-items: center;
    text-align: end;

    .divider.text {
        // width: 22.4px;
    }

    .dropdown.icon {
        /* top: unset !important;
        right: 18px;
        float: right;
        padding: 0px; */
        padding: 0px;
        padding-left: 4px;
        position: relative;
        right: unset;
        top: unset;
        left: unset;
        margin: 0px;
    }
    
    .menu {
        margin-left: @base-margin
    }
}

.timeSelection

.ui.form .fields .field.timeField:not(:first-child) .ui.selection.dropdown .menu 
.fields .field.timeField:not(:first-child) .ui.selection.dropdown .menu {
    margin-left: -@base-margin * 2;
}