@import './variables.less';

.qrScanner {
    width: 300px;
    height: 300px;
}

.qrScanner > div {
    padding-top: 0px!important;
    height: 100%!important;
}

.qrContainer {
    position: absolute;
    width: 300px;
    height: 300px;
    z-index: 100000;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.qrContainer  .ui.button.cameraFlip {
    position: absolute; 
    bottom: 12px;
    background-color: @purple
}

.qrContainer  .ui.button.macro {
    position: absolute; 
    bottom: 12px;
    left: 12px;
    background-color: @yellow;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
}

.qrContainer  .ui.button.macro.active {
    opacity: 1;
    background-color: @yellow;
}

.qrContainer  .ui.button.macro.inactive {
    opacity: .6;
    background-color: @yellow;
}

.qrContainer.dynamicListScanner {
    margin-bottom: @base-margin*4;
    position: relative;
}

.qrContainer .qrError {
    height: 100%; 
    width: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 0px @base-padding * 2
}

.qrContainer .fas.fa-times {
    position: absolute; 
    top: 8px; 
    right: 12px;
    cursor: pointer;
}

.qrContainer.qrField video {
    top: -2px !important;
}

#qrVideo {
    top: -2px!important
}