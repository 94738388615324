@import "./variables.less";

.passwordForm {
    width: 500px;
}

.passwordTable{
    width: fit-content;
    font-family: @primary-font-black;
}

.passwordTable.ui.table {
    background: transparent;
    letter-spacing: 1px;
    font-size: @font-size-s;
    color: @root-white;
    padding-bottom: @base-padding * 2;
}

.passwordTable.ui.table td {
    padding: 6px @base-padding 6px @base-padding;
}

.ui.table td.passwordCellLabel {
    padding-top: 10px;
}

.setPasswordButton.ui.button {
    margin: 10px;
    font-size: @font-size-s;
    background: transparent;
    color: @main-white;
    font-family: @primary-font-black;
    padding: @base-padding*2 @base-padding*4 @base-padding*2 @base-padding*4;
}