@-moz-document url-prefix() {
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield !important;
    }

    textarea {  
        resize: none; /* disables resizability */  
    }
}