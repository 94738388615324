@import "./variables.less";

.starRating {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    line-height: normal;
    padding-top: @base-padding * 3; 
    padding-bottom: @base-padding * 3;
}

.starRating i > span {
    position: absolute;
    font-size: @font-size-m*2;
    color: @main-white;
    font-family: @primary-font-bold;
    position: absolute;
    margin-left: (@base-margin+2)*-11-1;
    margin-top: @base-margin*8;
}

.starRating > .title {
    font-size: @font-size-xs*2;
    font-family: @primary-font-bold;
    margin-left: @base-margin - 3;
    color: @main-white;
    letter-spacing: @letterSpacing;
    margin-bottom: @base-margin;
}

.issues, .issues .insight  {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.issues > .title {
    padding-left: @base-padding*4;
    padding-bottom: @base-padding - 1;
    font-size: @font-size-xl;
    font-family: @primary-font-bold;
    color: @main-white;
    letter-spacing: @letterSpacing;
}

.issues .insight {
    display: flex;
    justify-content: center;
    align-items: center;
}

.issues .insight .loadWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}

.issues .insight .loadWrapper .ui.loader {
    position: absolute;
    left: auto;
    bottom: auto;
    top: auto;
    right: auto;
    transform: none;
}

.insight .allGood {
    font-size: @font-size-m*6;
    color: grey ;
}

.insight .allGood i {
    margin-bottom: @base-margin*2;
}

.insight .allGood > div {
    font-family: @primary-font-bold;
    font-size: @font-size-xl;
    letter-spacing: @letterSpacing;
    text-align: center
}

.insight .listHolder {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.insight .list {
    width: 100%;
    color: @main-white;
}

.insight .listHolder .ui.list {
    font-family: @primary-font-bold;
    font-size: @font-size-s;
    letter-spacing: @letterSpacing;
}

.insight .listHolder .ui.list .item {
    padding-top: @base-padding *2;
    padding-bottom: @base-padding *2;
    padding-right: @base-padding * 2;
    padding-left: @base-padding * 2;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.insight .listHolder .ui.list .item .itemText {
    width: 100%;
}

.insight .listHolder .ui.list .item .itemText .deletedDevice {
    text-decoration: line-through;
}

.insight .listHolder .ui.list .item .rightSide {
    display: flex;
    margin-left: @base-margin * 3;
    flex-direction: column;
    width: 12px;
    align-items: center;
    justify-content: center;
}

.insight .listHolder .ui.list .listSpacer {
    height: 2px;
    background-color: fade(@main-white, 50%);
    border-radius: 1px;
}

.ui.top.center.popup.infoPopUp {
    background-color: @darkGrey;
    color: @root-white;
    border: @primaryBorder
}