@import "./variables.less";

.ui.dropdown.filterDropdown > .menu > .item:hover {
    background: transparent!important;
    z-index: 1;
}

.ui.dropdown.filterDropdown  .menu > .header {
    color: @root-white
}

.filterTrigger {
    cursor: pointer;
}

.ui.dropdown.filterDropdown > .icon {
    display: none;
}

.ui.dropdown.dropdown.filterDropdown > .menu {
    z-index: 100000000;
    overflow-y: auto;
    height: fit-content;
    transition: max-width @transitionTime, max-height @transitionTime;
    min-width: 242px !important;
    right: -16px!important;
}

.ui.dropdown.dropdown.filterDropdown .menu > .input input{
    background-color: transparent;
    color: @root-white;
    border: @secondaryBorderWidth @root-white solid;
    border-radius: @baseBorderRadius * 12
}

.ui.dropdown.dropdown.filterDropdown .menu > .input i{
    color: @root-white;
}

.filterDropdown .ui.selection.dropdown {
    border: @secondaryBorderWidth @root-white solid;
    background-color: transparent;
    color: @root-white;
    border-radius: @baseBorderRadius * 12;
    width: 100%;
    height: 44px
}

.filterDropdown .ui.selection.dropdown.aresSelect  {
    border-radius: @baseBorderRadius * 6;
}

.filterDropdown .ui.selection.dropdown.aresSelect .menu::-webkit-scrollbar-track {
    margin-bottom: @base-margin * 5;
}

.chartContainer .filterDropdown .ui.selection.dropdown.aresSelect  {
    border-radius: @baseBorderRadius * 6;
    display: flex;
    align-items: center;
}

.ui.selection.visible.dropdown.aresSelect  > .text:not(.default) {
    color: @root-white;
}

.ui.dropdown.selected, .ui.dropdown.aresSelect  .menu .selected.item {
    color: fade(@root-white, 50%);
}

.ui.selection.active.dropdown.aresSelect .menu {
    right: unset;
    width: calc(100% + 4px )!important;
    position: absolute;
    left: -2px;
    top: 40px!important;
    border-radius: 0px 0px 24px 24px!important;
    border: 2px solid @root-white;
}

.filterDropdown  .ui.selection.dropdown:hover {
    border-color: @root-white;
}

.ui.dropdown.filterDropdown .menu > .item .dropdown.icon {
    padding: 0px;
    margin: -4px 0px 0px 0px!important;
}

.ui.dropdown.filterDropdown > .menu > .item {
    padding-top: 0px!important;
}

.ui.dropdown.filterDropdown .menu > .item .header {
    margin-top: @base-margin * 3;
}

.aresSelect .ui.icon.input input {
    background: transparent;
    border: 2px @root-white solid;
    height: 44px;
    width: 100%;
    color: @root-white!important;
    padding-left: 16px;
}

.ui.selection.dropdown.aresSelect.left {
    border-right: 0px;
    border-radius: 24px 0px 0px 24px;
    width: 18%;
    min-width: 0px;
    padding-right: 5px;
}

.ui.selection.dropdown.aresSelect.left > i{
    display: none;
}

.ui.selection.dropdown.aresSelect.left > .divider.text i
{
   font-size: 13px;
}

.ui.selection.dropdown.aresSelect.right {
    border-left: 0px;
    min-width: 0px;
    border-radius: 0px 24px 24px 0px;
    width: 82%;
}

.aresSelect .ui.icon.input {
    width: 100%;
}

.flexcenter {
    display: flex;
    justify-content: center;
}

.ui.dropdown.filterDropdown .menu > .divider {
    border-color: @root-white;
}

.ui.toggle.checkbox.filterToggle  input:checked ~ .box, .ui.toggle.checkbox.filterToggle input:checked ~ label {
    color: @root-white!important
}

.ui.toggle.checkbox.filterToggle input:focus:checked ~ .box, .ui.toggle.checkbox.filterToggle  input:focus:checked ~ label {
    color: @root-white!important
}

.ui.checkbox.filterToggle input:focus ~ label {
    color: @root-white
}

.ui.toggle.checkbox.filterToggle  input:checked ~ .box, .ui.toggle.checkbox.filterToggle input:checked ~ label::after,
.ui.toggle.checkbox.filterToggle  input:checked ~ .box, .ui.toggle.checkbox.filterToggle input ~ label::after {
    z-index: 1;
}

.ui.dropdown .menu > .item .header,
.ui.dropdown .menu > .item .header:hover,
.ui.dropdown .menu > .item .header:focus {
    color: @root-white!important;
    opacity: 1;
    cursor: default
}

.ui.button.filterBtns {
    border: 2px solid @root-white
}

.ui.toggle.checkbox.filterToggle .box, .ui.toggle.checkbox.filterToggle label {
    color: fade(@root-white,50%)
}

.ui.buttons.filterButtonGroup {
    width: 100%
}

.ui.buttons.filterButtonGroup .ui.mini.button {
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
}

.ui.buttons.filterButtonGroup .ui.mini.button:first-child {
    border-right-width: 0px;
}

.ui.buttons.filterButtonGroup .ui.mini.button:last-child {
    border-left-width: 0px;
}

.ui.buttons.filterButtonGroup .ui.mini.button.active.allOption {
    color: @lightBlue!important;
}

.ui.buttons.filterButtonGroup .ui.mini.button.active.statusTrue {
    color: @green!important;
}

.ui.buttons.filterButtonGroup .ui.mini.button.active.statusFalse {
    color: @lightRed!important;
}

.ui.buttons.filterButtonGroup .ui.mini.button.active.statusError {
    color: @basketBallOrange!important
}