/*******************************
         Theme Overrides
*******************************/

.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 6px 18px 6px 18px;
}

.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 8px 16px 8px 16px;
}

.ui.buttons .button,
.ui.buttons .or,
.ui.button {
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    padding-left: 48px;
    padding-right: 48px;
}


/***** Button Groups ******/

/*.ui.mini.buttons {
    border: 2px solid white;
    border-radius: 48px
}*/
