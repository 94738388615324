@import "./variables.less";

.inputLabel {
    font-size: @font-size-s;
    text-align: left;
}

.ui.button.saveEditButton {
    color: @root-white;
    letter-spacing: @letterSpacing;
    font-size: @font-size-s;
    background-color: @basicButtonBackground;
    padding: @base-padding*2 @base-padding*4 @base-padding*2 @base-padding*4;
    margin-left: @base-margin * 2;
    margin-right: @base-margin * 2;
}

.ui.button.saveEditButton > .icon:not(.button) {
    opacity: 1.0;
}

.ui.inverted.button.saveEditButton:focus {
    color: @root-white;
    background-color: @basicButtonBackground;
}

.ui.inverted.button.saveEditButton:hover {
    background-color: @root-white;
    color: black;
}

.dynamicForm.accountInfo table tr td:nth-child(2) {
    width: 400px;
}

.dynamicForm.accountInfo {
    padding-left: @base-padding * 5;
    padding-right: @base-padding * 5;
}
