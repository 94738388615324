@import "./variables.less";

/*-------------------
      Ares Modal
--------------------*/
.ui.modal.aresModal {
    background-color: @opaqueBlack;
    overflow: visible;
    background: @bgImg no-repeat center center fixed;
    max-width: @modalWidth;
    border-radius: @baseBorderRadius*8;
}

.ui.modal.aresModal .ui.form {
    width: 100%;
    padding: 0px @base-padding*4;
    font-family: @secondary-font;
}

.ui.modal.aresModal.glass {
    position: absolute;
    background-image: inherit;
    z-index: 1;
}

.ui.modal.aresModal.glass::before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: -32px;
    left: -32px;
    right: 0px;
    bottom: -32px;
    filter: saturate(110%) brightness(90%) blur(16px);
}

.ui.modal.aresModal hr {
    border-bottom: @primaryBorder;
    border-top: none;
    border-right: none;
    border-left: none;
}


.ui.modal.aresModal .ui.form textarea {
    resize: none
}

.ui.modal.aresModal .ui.form .fields.aresFormGroup {
    flex-direction: column;
    margin: 0em 0em 0em;
    width: 100%;
    min-width: none;
}

/*-------------------
      Ares Modal Header
--------------------*/
.ui.modal.aresModal > .header {
    width: 100%;
    background: none;
    background-color: @opaqueBlack;
    color: @main-white;
    border: @primaryBorder;
    border-bottom: 0px;
    border-radius: @baseBorderRadius*7 @baseBorderRadius*7 0px 0px;
}

.ui.modal.aresModal > .header .closeButton > i:hover {
    cursor: pointer;
    color: fade(@main-white, 80%)
}

/*-------------------
      Ares Modal Body
--------------------*/
.ui.modal.aresModal > .content {
    display: flex;
    background: none;
    background-color: @opaqueBlack;
    border-bottom: 0px;
    justify-content: center;
    padding: @base-padding @base-padding*4;
    border: @primaryBorder;
    border-top: 0px;
    border-bottom: 0px;
    color: @root-white
}

.ui.modal.aresModal.wizardModal {
    max-width: @wizardModalWidth;
}

.ui.modal.aresModal.wizardModal .dynList {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.modal.aresModal.wizardModal .dynList > div:not(.dynamicListScanner) {
    width: 100%;
}

.ui.modal.aresModal.wizardModal > .content .wizardModalContent {
    position: relative; 
    display: flex; 
    flex-direction: column;
    width: 100%;
}

.ui.modal.aresModal.wizardModal.bulkQrAddModal > .content .wizardModalContent {
    width: auto
}

.ui.modal.aresModal.wizardModal > .content .ui.steps .step {
    padding: @base-padding @base-padding * 3;
} 

.ui.modal.aresModal.wizardModal > .content .ui.steps .step .title {
    font-size: @font-size-3xs;
}

.ui.modal.aresModal.wizardModal > .content .ui.steps .step:before {
    font-size: @font-size-3xs;
    margin-right: @base-margin;
}

.ui.modal.aresModal.wizardModal > .content .ui.steps .completed.step:before {
    color: @green
}

.ui.form .fields.deviceSettingsForm {
    display: flex;
    flex-direction: column;
}

.importContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*-------------------
      Ares Modal Footer
--------------------*/
.ui.modal.aresModal > .actions {
    background: none;
    background-color: @opaqueBlack;
    border: @primaryBorder;
    border-top: 0px;
    border-radius: 0px 0px @baseBorderRadius*7 @baseBorderRadius*7;
    z-index: -1;
    position: relative;
    width: 100%;
}

.ui.modal.aresModal.wizardModal .actions {
    display: flex;
    justify-content: space-between;
}

.ui.modal.aresModal .actions .wizardBttn {
    margin-left: @base-margin * 2;
}

.ui.modal.aresModal .actions .wizardBttn > i {
    margin-right: @base-margin * 2;
}

/*-------------------
      Ares Modal Tab
--------------------*/
.aresModalTab > .ui.segment  {
    padding: @base-padding*8 @base-padding*0 0px @base-padding*0;
    border: 0px;
}

.aresModalTab .ui.segment.active.tab,
.aresModalTab > .ui.compact.inverted.menu {
    background: transparent;
}

.aresModalTab > .ui.compact.inverted.menu a {
    border-bottom: @secondaryBorder;
    border-top:  @secondaryBorder;
    justify-content: center;
    padding: @base-padding*2 @base-padding*4@base-padding*2 @base-padding*4;
    box-shadow: none;
    min-width: 100px;
    font-size: @font-size-3xs;
}

.aresModalTab > .ui.compact.inverted.menu a.active.item {
    background: @tableLegendBackground;
}

.aresModalTab > .ui.compact.inverted.menu a:first-child {
    border-left:  @secondaryBorder;
    border-radius: @baseBorderRadius*9 0px 0px @baseBorderRadius*9;
    border-right: 0px;
}

.aresModalTab > .ui.compact.inverted.menu a:last-child {
    border-right:  @secondaryBorder;
    border-radius: 0px @baseBorderRadius*9 @baseBorderRadius*9 0px;
    border-left: 0px;
}

.aresModal.compactedTabs .aresModalTab > .ui.compact.inverted.menu a {
    min-width: 90px
} 

/*-------------------
      Ares Modal Interfacing
--------------------*/
.ui.modal.aresModal .interfaceTable {
    overflow-x: auto;
}

.ui.modal.aresModal .interfaceContainer {
    position: relative;
    color: @main-white!important;
}

.ui.modal.aresModal .interfaceContent {
    display: flex;
}

.ui.modal.aresModal .interfaceSettings {
    padding: @base-padding*5;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius*4;
    background-color: inherit; 
}

.ui.modal.aresModal .interfaceSelect .ui.selection.active.dropdown .menu {
    max-height: 150px;
}

.ui.modal.aresModal .interfaceSettings .params > .param {
    padding-bottom: @base-padding*3;
}

.methodSelect {
    padding-left: .5em;
    padding-right: .5em;
}

.methodSelect .ui.selection.active.dropdown > .menu{
    max-height: 88px;
}

/*-------------------
      *Ares Modal Error Message Block
--------------------*/
.ui.modal.aresModal .ui.red.message {
    box-shadow: none;
    border: 2px solid @red!important;
}

/*-------------------
      Ares Modal Properties Box
--------------------*/
.ui.modal.aresModal .propertiesBox {
    width: 100%;
    border: @secondaryBorderWidth solid;
    border-radius: @baseBorderRadius * 6;
    padding: @base-padding*2 @base-padding*3;
    display: flex;
    justify-content: center;
}

.ui.modal.aresModal .propertiesBox pre {
    overflow: auto;
}

.ui.modal.aresModal .propertiesBox.error {
    border-color: @basketBallOrange
}

.ui.modal.aresModal .propertiesBox > tbody {
    width: 100%;
    display: block;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.ui.modal.aresModal .propertiesBox > tbody > tr {
    display: flex;
    width: 100%;
    justify-content: center;
}

.ui.modal.aresModal .propertiesBox > tbody > tr > td {
    width: 50%;
}

.ui.modal.aresModal .propertiesBox > tbody > tr > td:first-child {
    text-align: end
}

.ui.modal.aresModal .propertiesBox > tbody > tr > td:last-child > i {
    margin-left: @base-margin;
    margin-right: @base-margin
}

.ui.modal.aresModal .propertiesBox.status > tbody > tr > td:first-child {
    width: 41%;
}

.ui.modal.aresModal .propertiesBox.status > tbody > tr > td {
    width: 59%;
}

.ui.modal.aresModal .propertiesBox > tbody > tr > td.noErrors {
    width: 100%;
    text-align: center;
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*-------------------
      Ares Modal Toggle
--------------------*/
.ui.modal.aresModal .ui.toggle.checkbox .box:before, 
.ui.modal.aresModal .ui.toggle.checkbox label:before {
    opacity: .5;
}

.ui.modal.aresModal .ui.toggle.checkbox input:focus:checked ~ .box:before, 
.ui.modal.aresModal .ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.modal.aresModal .ui.toggle.checkbox input:checked ~ .box:before, 
.ui.modal.aresModal .ui.toggle.checkbox input:checked ~ label:before {
    opacity: .9;
    background-color: @purple !important;
}


/*-------------------
      Ares Modal Field Label
--------------------*/
.fieldLabel {
    font-size: @font-size-2xs; 
    font-family: inherit;
    margin-bottom: @base-margin;
    display: flex;
}

.fieldLabel > .symbol {
    opacity: .7;
    margin-left: @base-margin;
}

.fieldLabel.error {
    color: @basketBallOrange
}


/*-------------------
      Ares Modal Button
--------------------*/
.ui.modal.aresModal .ui.button {
    border: @primaryBorder;
}

.ui.modal.aresModal .ui.button.extra-small {
    font-size: @font-size-2xs;
    padding-left: @base-padding*6;
    padding-right: @base-padding*6;
}

.ui.modal.aresModal .ui.button.save {
    color: @main-white
}

/*-------------------
      Ares Modal Inline Error
--------------------*/
.ui.modal.aresModal .inLineError {
    color: @lightRed;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*-------------------
      Object Builder
--------------------*/
.objectBuilder.disabled {
    opacity: .5;
    pointer-events: none;
}

.objectBuilder > .objectItems > hr {
    margin: @base-margin * 4 0px;
}

.objectBuilder > .objectItems > .objectBuilderItem {
    display: flex; 
    flex-direction: row;
}

.objectBuilderItem > div > div > .fa-solid .fa-xmark {
    cursor: pointer;
}

.objectBuilder > div > .fa-solid.fa-circle-plus {
    cursor: pointer;
}

.objectBuilder.head {
    width: 100%; 
    display: flex; 
    justify-content: center;
    padding: 0 @base-padding * 2 0 0;
}

.objectBuilderItem .addObjectButton {
    background: transparent
}

.objectBuilder.head > .ui.button.addObjectButton {
    width: 100%
}

.objectBuilder.head > .ui.button.addObjectButton.addAuth,
.ui.modal.aresModal .ui.button.addObjectButton.addAuth {
    background-color: @iceBlue!important;
}

.objectBuilder.head > .ui.button.addObjectButton.addAuth:hover,
.objectBuilder.head > .ui.button.addObjectButton.addAuth:focus,
.ui.modal.aresModal .ui.button.addObjectButton.addAuth:hover,
.ui.modal.aresModal .ui.button.addObjectButton.addAuth:focus {
    background-color: @iceBlue!important;
}

.objectBuilder.head > .ui.button.addObjectButton.addObject,
.ui.modal.aresModal .ui.button.addObjectButton.addObject {
    background-color: @iceBlue;
}

.objectBuilder.head > .ui.button.addObjectButton.addObject:hover,
.objectBuilder.head > .ui.button.addObjectButton.addObject:focus,
.ui.modal.aresModal .ui.button.addObjectButton.addObject:hover,
.ui.modal.aresModal .ui.button.addObjectButton.addObject:focus {
    background-color: @iceBlue!important;
}

.ui.modal.aresModal .ui.button.addObjectButton.showHideButton,
.ui.button.addObjectButton.showHideButton {
    width: 100%;
    border-width: 0px;
    border-radius: 0px;
}

.objectBuilderItem .addObjectItem {
    width: 100%; 
    display: flex; 
    justify-content: center;
}

.ui.form .objectBuilderItem > div >  .disabled.field,
.ui.form .objectBuilderItem > div >  .disabled.field > .disabled > input,
.ui.form .objectBuilderItem > div >  .disabled.field > .disabled,
.ui.form .objectBuilderItem > div > .disabled .fieldLabel {
    opacity: 1;
    border-color: transparent;
}

.ui.form .objectBuilderItem > div >  .disabled.field .dropdown.icon {
    display: none;
}

.objectBuilder .title > i {
    cursor: pointer;
}

.objectBuilderItem .objectBuilderItemTag {
    height: 32px; 
    padding: 2px 8px;
    border: @secondaryBorder; 
    border-radius: 24px; 
    display: flex; 
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.ui.field.objectBuilderField,
.objectBuilderField {
    padding: 0px;
}

.objectBuilderField .objectItems {
    width: 100%
}

.objectBuilderField .objectItems > .objectBuilderItem > div:first-child {
    // display: none;
}

.objectItems > .objectBuilderItem > div:not(:last-child){
    margin-top: @base-margin * 2;
}

.objectBuilderField .objectItems > .objectBuilderItem > div:not(:last-child){
    margin-top: @base-margin + 2;
    margin-right: @base-margin;
}

.aresFormGroup .aresFormListItem > div:first-child {
    display: block!important;    
}

.aresFormGroup .aresFormListItem > div:nth-child(2) {
    width: 155px;
}

.aresFormGroup .aresFormListItem > div.field:nth-child(2) > .selection.dropdown {
    min-width: 0px!important;
}

.aresFormGroup .aresFormListItem > div.objectBuilderField:nth-child(2) {
    display: flex;
}

.aresFormGroup .aresFormListItem .fieldLabel {
    height: 32px;
    align-items: center;
}

.aresFormGroup .aresFormListItem {
    display: flex;
    justify-content: space-between;
}

.aresFormGroup .aresFormGroupTabs {
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    height: 500px;
}

.aresFormGroup .aresFormGroupTabs > .aresFormGroupTab {
    width: 300px; 
    padding-right: @base-padding * 4; 
    padding-left: @base-padding * 4; 
    overflow-y: auto;
}

.aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.left {
    overflow-y: scroll;
}

.aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.right > i.backButton {
    display: none;
}

/*-------------------
    Ares Modal Field Multi Input
--------------------*/
.multiInput {
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    cursor: text;
    writing-mode: horizontal-tb !important;
    margin: 0em;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-image: initial;
    padding-block: 1px;
    padding-inline: 2px;
}

.multiInput:empty::before {
    content: ""
}

.multiInput:empty {
    content: ""
}

.ui.mult > .multiInput {
    display: block;
    padding: @base-padding / 2 @base-padding * 2;
    font-size: 1em;
    color: @root-white;
    border: 0px;
    box-shadow: none;
    transition: color 0.1s ease, border-color 0.1s ease;
    outline: 0px solid transparent;
    white-space: nowrap;
    padding-block: 1px;
    padding-inline: 2px;
    overflow: hidden;
    width: 100%
}

.ui.mult {
    line-height: 24px;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 6;
    height: 32px;
    display: inline-flex;
    padding: @base-padding @base-padding * 3;
    min-width: 0px;
    width: -webkit-fill-available;
    width: 100%;
    overflow-x: hidden;
}

.multiInput > .inputPart.var {
    color: @typeColor;
}

.multiInput > .inputPart.var > span {
    outline: transparent;
}

.multiInput > .inputPart.var.edit {
    background-color: fade(black, 10%);
}

/*-------------------
    Ares Modal Field Group
--------------------*/
.aresModalFieldGroup {
    padding: 0px (@base-padding * 2) - 1;
}

.aresModalFieldGroup > .field {
    padding: 0px (@base-padding * 2) - 1;
}

/*-------------------
    Preview Mode Colors
--------------------*/

.objectBuilder .previewMode {
    border: @secondaryBorder;
    padding: @base-padding * 2;
    border-radius: @baseBorderRadius * 4;
    overflow: auto;
}

.previewMode {
    overflow-x: auto;
}

.previewMode .string,
.previewMode .type,
.previewMode .authorization { 
    color: @stringColor; 
}

.previewMode .keyName {
    color: @bracketColor;
}

.previewMode .number { 
    color: @numberColor; 
}

.previewMode .boolean { 
    color: @booleanColor; 
}

.previewMode .type { 
    color: @typeColor;
}

.previewMode .system {
    color: @systemColor
}

.previewMode .null { 
    color: @nullColor 
}

.previewMode .key { 
    color: @root-white; 
}

.previewMode .bracket { 
    color: @curlyBracketColor 
}

.previewMode .bracket2 { 
    color: @bracketColor
}

.previewMode .authorization { 
    color: @typeColor 
}

/*-------------------
    Wizard Modal
--------------------*/
.wizardModalDivider {
    width: 100%;
}

.cancel.wizardBttn { 
    width: 100%;
}

.wizardModalStatusContainer {
    max-height: 400px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 @base-padding*3;
    //margin: @base-margin * 3 0;
}

.wizardModalContentDivider {
    border-bottom-width: 1px;
}

.wizardModalContent .ui.form .fields {
    margin-bottom: 0;
}

.statusIcon {
    float: right;
}

.deviceStatus {
    display: flex;
    align-items: center;
    padding: @base-padding 0;
    .deviceName {
        padding-left: @base-padding / 2;
    }
}

.arrayField .ui.pointing.above.prompt.label {
    display: none;
}

.arrayField > div:first-child {
    margin-right: @base-margin * 2;
}

.arrayField > .field {
    min-width: 50px;
    margin-right: @base-margin * 2!important
}

.arrayField .fieldLabel {
    width: 19.77px
}

.arrayFieldBox {
    padding: @base-padding * 6;
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 4;
}

.notificationContainer {
    padding: 0 0.5em;
    margin-left: @base-margin * 8;

}

.notificationType {
    display: flex;
    font-family: @secondary-font-bold;
    font-size: @font-size-2xs;
    i {
        width: 25px;
        font-size: @font-size-xl;
        color: @lightRed;
    }
    .typeLabel {
        margin: auto 0;
        padding-left: @base-padding * 3;
    }
}

.notificationType.enabled i {
    color: @green;
}

.aresModal .ui.multiple.search.dropdown > input.search {
    margin: 0px
}

.aresModal .ui.form .ui.multiple.search.selection.dropdown > input.search {
    padding: 0px
}

.aresModal .ui.multiple.dropdown > .label {
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 4;
    margin-right: @base-margin;
    margin-top: @base-margin;
    background-color: @green;
    position: relative;
    font-size: @font-size-3xs;
    box-shadow: none;
}

.aresModal .ui.form .disabled.field,
.aresModal .ui.form .disabled.field > div {
    opacity: 1
} 

.aresModal .ui.form .disabled.field .ui.disabled > input {
    border-width: 0px;
}