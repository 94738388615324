@import "./variables.less";

.reports-card {
    width: 225px;
    min-height: 315px;
    float: left;
    border-radius: @baseBorderRadius*7;
    padding-top: @base-padding*2;
    padding-bottom: @base-padding*2;
    margin-top: @base-margin*2;
    margin-bottom: @base-margin*2;
    margin-right: @base-margin*2;
    margin-left: @base-margin*2;
    border: @primaryBorderWidth @main-white solid;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @main-white;
    font-size: @font-size-m;
    background-color: @tableLegendBackground;
    line-height: 28px;
    transition: transform .5s;
}

.alert-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.alert-card > * {
    margin-bottom: @base-margin*5;
}

.alert-card > :last-child {
    margin-bottom: 0;
}

.alert-card-icon-container {
    height: 60%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: @base-margin * 5;
}

.alert-card-icon {
    width: 106px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: @base-margin*3;
    font-size: @font-size-s;
}

.alert-card-icon.single {
    margin-top: 0px;
}

.tricorn-divider {
    position: absolute;
}

.tricorn-divider line {
    stroke: @main-white;
    stroke-width: @base;
    stroke-linecap: round;
}

.icon-divider {
    height: @base*22;
    width: @base;
    background: @main-white;
    border-radius: @baseBorderRadius;
}

.card-timeframe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: @font-size-s;
}

.card-timeframe-container a {
    color: @main-white;
    :hover {
        color: fade(@main-white, 70%);
    }
}

.card-timeframe-container button {
    margin: @base-margin 0 !important;
}

.timeframe-button {
    padding: @base-margin*2 @base-margin*6 !important;
}

.monthly-reports-container {
    pointer-events: all;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    //text-align: center;
}

.reportTabs > div {
    cursor: pointer;
    opacity: 50%
}

.reportTabs > div.active {
    pointer-events: none;
    opacity: 100%;
    text-decoration: underline;
}

.reportTab:hover {
    opacity: 30%;
}

.reportTab:first-child {
    margin-right: @base-margin * 2
}

.reportTab:last-child {
    margin-left: @base-margin * 2
}