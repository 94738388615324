@import './variables.less';

@gaugeWidth: 16;
@gaugeBorderWidth: 0;

.gauge.unavailable {
    filter: grayscale(1);
    opacity: .5;
}

.gaugeBackground,
.gaugeFill {
    fill: none;
    stroke-width: @gaugeWidth;
    stroke-linecap: round;
}

.gaugeGlow {
    fill: none;
    stroke-width: @gaugeWidth;
    stroke-linecap: round;
    filter: url(#red-glow);
    animation: flashing 2s infinite;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    stroke-width: 12;
}

.gaugeTextGlow {
    filter: url(#gauge-text-glow)
}

filter#red-glow > feFlood {
    flood-color: rgba(224, 97, 103, 1);
}

filter#gauge-text-glow > feFlood {
    flood-color: rgba(224, 97, 103, 1);
}

@keyframes flashing {
    0% { opacity: 0 }
    30% { opacity: 1 }
    60% { opacity: 1 }
    100% { opacity: 0 }
}

.gaugeBorder {
    stroke-linecap: round;
    fill: none;
    stroke-width: @gaugeWidth + @gaugeBorderWidth;
}

.gaugeInfo {
    font-size: @font-size-2xs*2;
    font-family: @secondary-font-bold;
    text-anchor: middle;
}

.gaugeSymbol {
    font-size: @font-size-l;
}

.gaugeTitle, .gaugeStatus {
    font-size: @font-size-m;
    font-family: @secondary-font-bold;
    text-anchor: middle
}

.gaugeIcon {
    transform: translateX(115px) translateY(144px) scale(0.07);
}

.gaugeThresh {
    font-size: @font-size-s;
    font-family: @secondary-font-bold;
    text-anchor: middle
}

.gaugeValueRange {
    font-size: @font-size-xs;
    font-family: @secondary-font-bold;
    text-anchor: middle
}

.gaugeValueRange tspan.symbol {
    font-size: @font-size-m/2;
}

.gaugeThresh tspan.symbol {
    font-size: @font-size-2xs;
}

/** Colors **/
.gaugeBackground {
    stroke: fade(@darkGrey, 20%);
}

.gaugeBorder {
    stroke: rgb(200, 200, 200)
}

.gaugeTitle,
.gaugeThresh {
    fill: @root-white
}

.gaugeIcon { 
    stroke: @root-white
}