@import './variables.less';

.fillContainer {
    color: @root-white;
    min-width: none;
}

.fillContainer.column {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.fillContainer.insideScroller.column {
    height: calc(100vh - @topBarHeight - @pageHeaderHeight);
}

.fillContainer.column > .fillHeader {
    display: flex;
    height: fit-content;
    flex-direction: row;
    line-height: normal;
    font-family: @primary-font-bold;
    font-size: @font-size-l;
}

.fillContainer.column > .fillHeader > .fillHeaderLeft {
    padding-left: @fillPadding;
    width: fit-content;
    white-space: nowrap;
}

.fillContainer.column > .fillHeader > .fillHeaderRight {
    padding-right: @fillPadding;
    width: 100%
}

.fillContainer.column > .fillHeader > .fillHeaderLeft,
.fillContainer.column > .fillHeader > .fillHeaderRight {
    min-height: 100%;
}

.fillContainer.column > .fillContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    min-width: none;
    padding-left:  @fillContentPadding;
    padding-right: @fillContentPadding;
}

.fillContainer.column > .fillFitContent {
    display: flex;
    flex: none;
    flex-direction: column;
    min-width: none;
}

.fillContainer.column > .fillFooter {
    display: flex;
    height: fit-content;
    flex-direction: column;
}

.fillContainer.insideScroller.column > .fillFooter {
    flex: 1 1 0%;
}

.fillContainer.insideScroller.column > .fillFooter > .fillFooterContent {
    height: 100%;
}

.fillContainer.column > .fillFooter  > .fillFooterContent {
    min-height: @footerHeight;
    padding-left: @fillPadding;
    padding-right: @fillPadding;
}

.fillContainer.column > .fillLine > .line {
    background-color: @grey;
    border-radius: @baseBorderRadius;
    height: @fillLine;
}

.fillContainer.column > .fillLine {
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding-left: @fillPadding;
    padding-right: @fillPadding;
}

.fillContainer.column > .fillContent.dashboardInfo,
.fillContainer.column > .fillContent.weatherInfo,
.fillContainer.column > .fillContent.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.fillContainer.row {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
}

.fillContainer.row > .fillContent {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    min-width: none;
}

.fillContainer.row > .fillLine > .line {
    background-color: @grey;
    border-radius: @baseBorderRadius;
    width: @fillLine;
    height: 100%;
}

.fillContainer.row > .fillLine {
    display: flex;
    width: fit-content;
    flex-direction: column;
    padding-top: @base-padding;
    padding-bottom: @base-padding * 4;
    height: 100%;
}