.glass {
    box-shadow: 0px 0px 24px 0px rgba(20, 20, 20, 0.3); 
    z-index: 1;
    background: inherit;
    overflow: hidden;
}

.glass:before {
    content: "";
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(100, 100, 100, 0.2);
    /* filter: blur(16px);*/
    /*transform: translateZ(0);*/
    /*filter: saturate(110%) brightness(96%) blur(16px);*/
}

.pagebar {
    left: 0px;
    top: 0px;
    right: 0px;
    height: 57px;
    height: 48px;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-width: 0px;
    border-color: rgba(255, 255, 255, 0.9);
    
    z-index: -1;
    mix-blend-mode: hard-light;
}

.pagebar h1 {
    float: left;
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.99);
    font-size: 24px;
    font-weight: normal;
    margin-top: 8px!important;
    margin-left: 16px!important;
}

.pagebar i {
    font-weight: 900;
    font-size: 24px;
}

.pagebar i.icon {
    float: left;
    
}