.heatmapOuter {
    padding-top: 12px;
    width: 100%;
    min-height: calc(100vh - 260px);
}

.heatmapTable {
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}

.heatmapTable td {
    background-color: gray;
    text-align: center;
    cursor: pointer;
}

.heatmapTable tr > td:first-child {
    background-color: transparent;
    padding-left: 0px;
    padding-right: 8px;
}
.heatmapTable tr:last-child > td {
    background-color: transparent;
}

.heatMapToolTip {
    
}