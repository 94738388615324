@import "./variables.less";

.ui.checkbox.exportCheckbox input:hover ~ .box:before,
.ui.checkbox.exportCheckbox input:hover ~ label:before {
    background-color: @purple !important;
    border: @purple 2px solid !important;
}

.ui.checkbox.exportCheckbox input:focus ~ .box:before,
.ui.checkbox.exportCheckbox input:focus ~ label:before {
    background-color: transparent !important;
    border: @purple 2px solid !important;
}

.ui.checkbox.exportCheckbox label:active::before {
    background-color: @purple !important;
    border: @purple 2px solid !important;
}

.ui.checkbox.exportCheckbox .box:hover::before, 
.ui.checkbox.exportCheckbox label:hover::before {
    background-color: @purple !important;
    border: @purple 2px solid !important;
}

.ui.checkbox.exportCheckbox input:indeterminate ~ .box:before,
.ui.checkbox.exportCheckbox input:indeterminate ~ label:before {
    background-color: @purple !important;
    border: @purple 2px solid !important;
}

.ui.checkbox.exportCheckbox input:checked ~ .box:before,
.ui.checkbox.exportCheckbox input:checked ~ label:before {
    background-color: @purple !important;
    border: @purple 2px solid;
}

.ui.checkbox.exportCheckbox .box:before, 
.ui.checkbox.exportCheckbox label:before {
    background-color: transparent !important;
    border: @root-white 2px solid;
}

.ui.disabled.checkbox.exportCheckbox .box:hover::before,
.ui.disabled.checkbox.exportCheckbox label:hover::before {
    background-color: transparent !important;
    border: @root-white 2px solid;
}

.ui.disabled.checkbox.exportCheckbox {
    opacity: .4;
}

.ui.checkbox.exportCheckbox {
    width: fit-content;
}

.ui.button.importButton {
    margin-bottom: @base-margin*4;
    background: transparent;
    border-width: 2px !important;
    padding: @base-padding*2 @base-padding*4 @base-padding*2 @base-padding*4;
}

.ui.button.exportButton {
    margin-left: @base-margin;
    margin-right: 0;
    width: 131px;
    background: transparent;
    border-width: 2px !important;
    padding: @base-padding*2 @base-padding*4 @base-padding*2 @base-padding*4;
}

.ui.button.importButton:hover,
.ui.button.exportButton:hover {
    background: @basicButtonHover;
}

.checkboxContent {
    display: flex;
    flex-direction: column;
    margin: @base-margin*3;
}

.fileNameLabel {
    width: 100%;
    text-align: left;
    font-size: @font-size-3xs;
    margin-left: 0.5em;
    margin-bottom: @base-margin;
}

.fileNameContent {
    display: flex;
    width: 100%;
}