


.navMenuItem-grid {
    display: flex;
    flex-direction: column;
}

.navMenu-item {
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
    padding-left: 16px;
    margin-right: 8px;
    margin-top: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 0px 22px 22px 0px;
}

.navMenu-item:hover {
    cursor: pointer;
    color: rgb(163, 163, 163);
}



.navMenu-item > i.icon {
    margin-right: 4px;
    width: 28px;
    text-align: center;
}