.ui.form input[type="text"],
.ui.form input[type="text"]:focus,
.ui.form input[type="number"],
.ui.form input[type="number"]:focus,
.ui.form input[type="password"],
.ui.form input[type="password"]:focus,
.ui.form textarea,
.ui.form textarea:focus {
  background: transparent;
}

/*-------------------
      Form General
--------------------*/
.ui.form {
  font-size: @font-size-xs;
}

/*-------------------
      Autofill
--------------------*/
.ui.input input:-webkit-autofill,
.ui.input input:-webkit-autofill:hover, 
.ui.input input:-webkit-autofill:focus, 
.ui.input input:-webkit-autofill:active,
.ui.input input:-internal-autofill-selected,
.ui.input input:-webkit-autofill::first-line {
    font-family: inherit;
    transition: background-color 5000s!important;
    caret-color: inherit;
    border-color: inherit
}

.ui.form .field.field input:-webkit-autofill {
  box-shadow: 0px 0px 0px 100px @inputAutoFillBackground inset !important;
  border-color: @inputAutoFillBorder;
}

/*-------------------
      Phone
--------------------*/
.ui.form .field.phoneField {
  line-height: 0px
}

.ui.form .field.phoneField > .input input {
  width: calc(100% - @countryCodeWidth)
}


.ui.form .field.phoneField.inactive {
  pointer-events: none;
}

.ui.form .field.phoneField .ui[class*="left action"].input > input {
  border-left-width: 0px;
  padding-left: 0px;
}

.ui.form .field.phoneField.inactive .field.countryCode {
  pointer-events: all;
}

.ui.form .field.phoneField .phoneInputClear {
  position: absolute;
  right: 0;
  top: 50%;
  right: 4px;
  cursor: pointer;
}

.ui.form .field.countryCode {
  width: 90px;
  display: contents;
  margin: 0px;
  opacity: 1;
}

.ui.form  .field.countryCode .ui.active.selection.dropdown {
  border-top-right-radius: 0px
}

.ui.form .field.countryCode > div {
  min-width: @countryCodeWidth;
  max-width: @countryCodeWidth;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
  opacity: 1;
  padding: @base-padding * 2 @base-padding * 3;
}

.ui.form .field.countryCode input.search {
  width: 50px !important
}

.ui.form .countryCode .ui.selection.active.dropdown .menu {
  border: @secondaryBorder;
}

/*-------------------
      Input Number Field
--------------------*/
.ui.form .ui.input > input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  cursor:pointer;
  display:block;
  width:8px;
  text-align:center;
  position:relative;
  pointer-events: none;
}

.ui.form .field .qrButton {
  padding: 0px @base-padding * 2;
  font-size: @font-size-xs;
  background: rgba(20, 20, 20, 0.2);
  color: @main-white;
  border-style: solid;
  border-color: @inputFocusBorderColor;
  border-width: @primaryBorderWidth;
  border-left-width: 0px !important;
  border-radius: 0px @baseBorderRadius * 6 @baseBorderRadius * 6 0px !important;
}

.ui.form .field .qrButton:hover {
  background: rgba(20, 20, 20, 0.4);
}

.ui.form .field .ui.action.input:not([class*="left action"]) > input {
  border-right-width: 0px !important;
}

.ui.form .field.numberField > .ui.input > input[type=number] { 
  border-right: none;
}

.ui.form .ui.input > .spinner {
  color: @root-white;
  border: @secondaryBorder;
  display: flex;
  flex-direction: column;
  border-left: none;
  justify-content: center;
  padding: 0px @base-padding*5;
  border-radius: 0px @baseBorderRadius * 6 @baseBorderRadius * 6 0px;
  font-size: @font-size-3xs;
}

.ui.form .ui.input > .spinner > .fas.fa-caret-up {
  height: 8px
}

.ui.form .ui.input > .spinner > .fas.fa-caretdown {
  height: 12px
}

.ui.form .ui.input > .spinner i {
  cursor: pointer;
}

.ui.form .ui.input > .spinner i:hover {
  color: fade(@main-white, 40%);
}

/*-------------------
      Slider
--------------------*/
.ui.slider .rc-slider > .rc-slider-track {
  background-color: @purple;
} 

.ui.slider .rc-slider {
  border-color: @root-white;
}

.ui.slider .rc-slider,
.ui.slider .rc-slider .rc-slider-rail,
.ui.slider .rc-slider .rc-slider-track,
.ui.slider .rc-slider .rc-slider-handle {
  height: (@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2));
}

.ui.slider .rc-slider .rc-slider-handle {
  background-color: @root-white
}

.ui.slider .sliderValue {
  width: 120px
}

.ui.slider .sliderValue > .ui.input {
  height: 100%;
}

.ui.slider .sliderValue > .ui.input > input {
  padding: 0px 0px 0px 10px;
}

/*-------------------
      Error Field
--------------------*/
.ui.form .field.error .prompt.label {
  background: @errorLabelBackground !important;
  border-radius: @baseBorderRadius * 6;
  border-width: @secondaryBorderWidth !important;
  color: @red !important;
  display: block;
  font-size: @font-size-2xs;
}

/*-------------------
      Form Dropdown
--------------------*/
.ui.form .ui.selection.dropdown {
  line-height: (@inputLineHeight) - (@inputVerticalPadding * 2);
  padding: @inputPadding;
  min-height: (@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2));
}

.ui.form .ui.search.selection.dropdown > input.search {
  line-height: @inputLineHeight;
  padding: @inputPadding;
}

.ui.form .ui.selection.dropdown > .text {
  vertical-align: middle;
}

.ui.form .ui.selection.dropdown > .dropdown.icon {
  top: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) / 2;
  right: @inputHorizontalPadding * 3;
  padding: 0px;
}

.ui.form .ui.active.selection.dropdown {
  border-top-left-radius: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) / 2;
  border-top-right-radius: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) / 2;
}

.ui.form .ui.active.upward.selection.dropdown {
  border-bottom-left-radius: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) / 2!important;
  border-bottom-right-radius: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) / 2!important;
}

.ui.form .ui.selection.dropdown .menu > .item {
  display: flex;
  align-items: center;
  min-height: ((@inputLineHeight + (@inputVerticalPadding * 2) + (@secondaryBorderWidth * 2))) - 2
}

/*-------------------
      Form Label
--------------------*/
.ui.form .ui.label {
  background-image: none;
}