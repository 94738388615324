@import "./variables.less";

@media (max-width: 850px) {
    .mainHeaderBar {
        height: @mobileTopBarHeight
    }
    
    .navigationMenu::before {
        content: "";
        position: absolute;
        background: inherit;
        z-index: -1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        filter: saturate(110%) brightness(95%) blur(16px);
        clip-path: inset(0px 0px 0px 0px);
    }

    .navigationMenu { 
        left:-1*@navBarWidth;
        // transition: left 0s;
        top: @mobileTopBarHeight;
        background: inherit;
        width: 220px;
        z-index: 1000000000;
        transition-delay: .5s;
        transition-property: width, left;
    }
    
    .scroller {
        overflow-x: hidden;
    }

    .navigationMenu.active {
        transition-delay: 0s;
        left: 0px;
    }

    .main {
        top: @mobileTopBarHeight;
    }

    .page-container {
        margin-left: 0px ;
        // transition: all 0s;
    }
    
    .pageHeader {
        height: @tabletPageHeaderHeight;
    }

    .fillContainer.insideScroller.column {
        height: calc(100vh - @mobileTopBarHeight - @tabletPageHeaderHeight);
    }
    
    .viewFilter .ui.dropdown > span > span {
        display: none;
    }
    
    .settingsMenu {
        width: 48px;
    }

    .settingsMenu span {
        display: none;
    }

    .settingsMenu i {
        padding-left: 1px;
    }
    
    .navMenu-header {
        display: none
    }

    .infoHeader {
        font-size: 18px;
        display: inline-block;
        margin-top: 8px;
        letter-spacing: 0px;
    }

    .infoHeader > span >span:first-child {
        display: none;
    }

    .vSpacer {
        height: 24px
    }

    .crumb {
        font-size: 24px;
    }

    .navMenu-menuBtn {
        display: block;
    }

    .ui.small.button.back-button-visible.true {
        width: fit-content;
        left: 64px;
    }

    .chart-header.barchart {
        margin: 0 @base-margin * 5 0 @base-margin * 17.5;
    }

    .chart-header.linechart {
        margin: 0 @base-margin*20;
    }

    .backButtonContent > span {
        display: none;
    }

    .settingsMenu .caret.down.icon {
        display: none;
    }

    .loader > div {
        padding-right: 0px;
        margin-right: 0px;
    }

    .pageHeader h1.ui.header {
        margin-left: 8px;
    }
    
    .navMenu-item {
        font-size: 18px
    }

    .navMenu-item.active {
        background-color: fade(@main-white, 25%);
    }
    
    .pathText {
        display: none;
    }

    .parentSiteTrigger {
        display: flex;
    }
    
    .pathTextMobile {
        display: flex
    }

    .scrollController > div {
        justify-content: center;
    }

    .infoCards {
        display: none!important;
    }

    .infoCardPreLoader {
        display: none!important;
    }

    .mobileInfoCards {
        display: block;
    }
    
    .recent {
        padding-left:@base-padding*2
    }

    .page .row.middle.left {
        width: 100%;
    }

    .page .row.middle.right {
        width: 100%;
    }

    .page .ratings.row.middle.left {
        width: 100%;
        display: flex;
    }
 
    .page .ratings.row.middle.right {
        display: flex;
        display: none;
        width: 100%;
    }

    .page .ratings .title.bg {
        padding-top: @base-padding * 4;
        padding-bottom: @base-padding * 1.5;
        padding-left: @base-padding * 2;
    }
    
    .filterTrigger > span {
        display: none;
    }

    .aresModal .aresModalMenuItem.ui.compact.inverted.menu a,
    .aresModal.compactedTabs .aresModalMenuItem.ui.compact.inverted.menu a  {
        min-width: 80px;
        padding: @base-padding*3 @base-padding*3 @base-padding*3 @base-padding*3;
    }

    .starRating > .title {
        font-size: @font-size-m;
        display: none;
    }

    .notfound {
        font-size: @font-size-2xl+2;
    }
    
    .pdfButton .innerText {
        display: none;
    }

    .viewAlertsButton .innerText {
        display: none;
    }

    .accountInfoHolder {
        left: 0px;
        padding-left: @base-padding * 2;
    }

    .accountInfo {
        margin-top: 0px;
    }

    .accountInfoForm {
        padding-top: 0px
    }

    .reports-card-container {
        height: fit-content;
        justify-content: center;
        position: relative;
    }

    .scrollView.monthlyReport {
        transform: scale(0.8);
        transform-origin: top left;
    }

    .pdfButtonContainer {
        &.report {
            font-size: @font-size-4xl;
            cursor: pointer;
            pointer-events: all;
            z-index: 1;
        }
        &.barchart {
            padding-right: @base-padding * 5;
            padding-left: @base-padding * 17.5;
        }
        &.linechart {
            margin-right: @base-margin * 4.5;
        }
    }
    
    /* ------------------ Info Card ----------------- */
    .cardBox.infoCard {
        padding-top: @base-padding * 3;
        padding-bottom: @base-padding * 3;
        max-width: unset;
    }
    /* ---------------------------------------------- */

    /* -------------------- Views ------------------- */
    .cardView > .scrollView,
    .detailedView > .scrollView {
        align-items: center;
        justify-content: center ;
        width: 100%
    }

    .tableView tbody {
        height: calc(100vh - 195px);
    }

    .tableView > .tableFoot {
        height: @footerHeightMobile + @fillLine;
        padding-right: @base-padding * 4;
    }
    /* ---------------------------------------------- */

    /* ------------------ Card Box ------------------ */
    .cardBox {
        padding-left: @base-padding * 2;
        padding-right: @base-padding * 2;
        padding-top: @base-padding * 2;
        padding-bottom: @base-padding * 2;
        width: 47%;
    }

    .cardBox > .title {
        width: calc(100% - 24px);
    }

    .cardBox .rowCut {
        max-width: 120px,
    }

    .cardBox > .title, 
    .cardBox > .alertTime,
    .cardBox > .alertBox {
        margin-left: @base-padding*3;
        margin-right: @base-padding*3;
    }
    /* ---------------------------------------------- */

    /* ---------------- Detailed Box ---------------- */
    .detailedBox {
        padding-bottom: (@base-padding);
        padding-left: @base-padding;
        padding-right: @base-padding;
        width: 165px
    }
    /* ---------------------------------------------- */

    /* ---------------- Ares Button ----------------- */
    .ui.button.aresButton {
        padding-left: @base-padding;
        padding-right: @base-padding;
    }
    /* ---------------------------------------------- */

    .webSocketNotification.menuBtnNotification {
        right: 30px;
    }
}

@media screen and (max-width: 700px) {
    /* ---------------- Edit Modal Object Builder Wrapper ----------------- */
    .aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.left,
    .aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.right {
        width: 0px;
        display: none
    }

    .aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.left.active,
    .aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.right.active {
        width: 100%;
        display: block;
    }

    .aresFormGroup .aresFormGroupTabs > .aresFormGroupTab.right > i.backButton {
        display: block;
    }
    /* -------------------------------------------------------------------- */
}

/* When Screen width is less than 550 px */
@media screen and (max-width:550px), screen and (max-height: 550px) and (max-width:800px) {
    .ui.mini.buttons.legend-container {
        //flex-direction: column;
    }
    .ui.mini.buttons.legend-container > button {
        padding: @base-padding @base-padding*3;
        font-size: @font-size-2xs;
        margin-bottom: @base-margin;

    }

    .chartFilterContainer .viewFilter {
        padding: @base-padding @base-padding*3;
    }

    .gridContainer {
        justify-content: center;
    }

    .mainHeaderBarRight {
        padding-right: @base-padding * 2;
    }

    .pageHeader {
        height: @mobilePageHeaderHeight;
    }

    .fillContainer.insideScroller.column {
        height: calc(100vh - @mobileTopBarHeight - @mobilePageHeaderHeight);
    }

    .vSpacer {
        margin-right: @base-margin + 2;
        margin-left: 0px;
    }

    .pageHeader h1.ui.header {
        margin-top: @base-margin;
    }

    /* ------------------ Card Box ------------------ */
    .cardBox, .cardBox.s {
        margin-top: @base-margin;
        margin-bottom: @base-margin;
        max-width: calc(100vw - 32px);
        width: calc(100vw - 32px);
    }

    .cardBox .alertBox {
        margin-left: @base-margin * 2;
    }

    .cardBox .alertTable {
        margin-left: @base-margin * 4;
    }    
    /* ---------------------------------------------- */

    .pageHeader h1.ui.header {
        font-size: @font-size-l;
    }

    .pageHeader h1 > i:first-child {
        margin-right: @base-margin + 2;
    }

    .systemMessage {
        margin-top: @base-margin;
        margin-bottom: @base-margin;
    }

    .reports-card-container {
        height: fit-content;
        justify-content: center;
        position: relative;
    }

    .scrollView.monthlyReport {
        transform: scale(0.65);
        transform-origin: top left;
    }

    /* --------------- Fill Container --------------- */
    .fillContainer.column > .fillLine {
        padding-right: @fillPaddingMobile;
        padding-left: @fillPaddingMobile;
    }

    .fillContainer.column > .fillHeader > .fillHeaderLeft {
        padding-left: @fillPaddingMobile;
    }
    
    .fillContainer.column > .fillHeader > .fillHeaderRight {
        padding-right: @fillPaddingMobile;
    }

    .fillContainer.column > .fillContent {
        padding-left: @fillContentPaddingMobile;
        padding-right: @fillContentPaddingMobile;
    }

    .fillContainer.column > .fillFooter  > .fillFooterContent {
        min-height: @footerHeightMobile;
        padding-left: @fillPaddingMobile;
        padding-right: @fillPaddingMobile;
    }
    /* ---------------------------------------------- */
    /* ------------------ Data View ----------------- */
    .dataView .viewer {
        height: 100%;
        width: 100%;
        padding-left:  @fillContentPaddingMobile;
        padding-right: @fillContentPaddingMobile;
    }

    /* ---------------------------------------------------------- */
    /* ------------------ Filter Dropdown ----------------- */
    .ui.buttons.filterButtonGroup .ui.mini.button {
        font-size: @font-size-3xs;
        padding-left: @base-padding * 3;
        padding-right: @base-padding * 3;
    }
    /* ---------------------------------------------------- */

    .reportTopBar {
        padding-top: @base-padding * 1.5;
    }

    .reportTopBar.initial > .reportDatePicker {
        width: 100%;
    }

    .pdfButtonContainer.report {
        .ui.button.pdfButton {
            padding-left: @base-padding*5;
            padding-right: @base-padding*5;
        }
    }

    .ui.button.applyReportDate {
        margin-left: @base-margin;
        padding-left: @base-padding*3;
        padding-right: @base-padding*3;
    }

    .webSocketNotification.menuBtnNotification {
        right: 12px;
    }
}

@media screen and (max-width: 350px) {
    .ui.modal.aresModal .ui.button.extra-small {
        padding-left: @base-padding * 4;
        padding-right: @base-padding * 4;
    }
}

@media screen and (max-height: 670px) {
    @supports selector(:has(*)) {
        .ui.modal.aresModal.addDevice:has(.qrContainer) {
            min-height: 540px;
            box-shadow:  0px 0px 0px transparent
        }
    }

    @supports not selector(:has(*)) {
        .ui.modal.aresModal.addDevice {
            min-height: 540px;
            box-shadow:  0px 0px 0px transparent
        }
      }
}

wbr:after { content: "\00200B"; }
