@import './variables.less';

@menuBorderRadius: @base-padding * 3;

.ui.vertical.topBarMenu.menu {
    position: absolute;
    border-radius: @menuBorderRadius;
    border-width: @primaryBorderWidth;
    display: none;
    margin: 0px;
    top: 36px;
    overflow: hidden;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
}

.ui.vertical.topBarMenu.settings.menu {
    margin-top: @base-margin * 2;
    width: max-content;
}

.settingsMenuItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ui.vertical.topBarMenu.menu > a.item::before {
    height: 0px;
}

.ui.topBarMenu.menu a.item {
    padding: ((@base-padding * 2)) ((@base-padding * 2) + 2);
}

.ui.topBarMenu.menu a.item:first-child {
    border-radius: 0;
}

.ui.topBarMenu.menu a.item:last-child {
    border-radius: 0;
}

.subsiteTrigger {
    margin-right: 0px;
    float: left;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.subsiteTrigger > i.icon {
    width: 26px;
    height: fit-content;
    font-size: @font-size-xl;
    margin: 0px;
}

.parentSiteTrigger {
    margin-right: 0px;
    float: left;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    height: 100%;
    display: none;
    align-items: center;
    cursor: pointer;
}

.parentSiteTrigger > i.icon {
    width: 16px;
    height: fit-content;
    font-size: @font-size-xl;
    margin: 0px
}

.parentSiteTrigger > i.down.icon {
    display: none;
}

.parentSiteTrigger:focus > i.down.icon {
    display: block;
}

.parentSiteTrigger:focus > i.right.icon {
    display: none;
}