@import "./variables.less";

.dynamicForm {
    margin-top: @base-margin * 2;
    width: 100%;
}

.dynamicForm table {
    border-collapse: separate;
    border-spacing: @base-margin * 2;
}
.dynamicForm table.stacked {
    width: 100%;
}

.dynamicForm table tr.stacked {
   width: 100%;
}

.dynamicForm table tr:last-child td {
    padding-bottom: @base-padding * 7
}

.dynamicForm table:not(.stacked) tr td:nth-child(1) {
    font-family: @primary-font-black;
    vertical-align: middle
}

/* .dynamicForm table:not(.stacked) tr td:nth-child(2) {
    padding-bottom: 7px;
} */

.dynamicForm table tr.stacked {
    font-family: @primary-font-black;
}

.dynamicForm td.alignRight {
    text-align: right;
}

.dynamicForm .formButtonsHolder {
    margin-top: @base-margin;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.dynamicForm .formButtonsHolder .saveEditButton {
    margin-top: @base-margin;
    margin-bottom: @base-margin;
}

.dynamicForm .ui.form .disabled.field,
.dynamicForm .ui.form .ui.disabled.input,
.dynamicForm .ui.disabled.dropdown {
    opacity: 1;
}

.dynamicForm .ui.form .field .ui.disabled.input input,
.dynamicForm .ui.form .field > .ui.disabled.dropdown {
    border-color: transparent!important;
}

.dynamicForm .ui.form .field > .ui.disabled.dropdown > .dropdown.icon {
    visibility: hidden;
}

.dynamicForm .ui.form .ui.disabled.input > input::-webkit-input-placeholder {
    color: transparent;
    content: "";
}

.dynamicForm .ui.form .ui.disabled.input > input::-moz-placeholder {
    color: transparent;
    content: "";
}

.dynamicForm .ui.form {
    font-family: @primary-font-bold;
}

.dynamicForm .ui.form td > .field > .checkbox {
    padding-left: @base-padding * 3;
    padding-top: @base-padding * 2;
    padding-bottom: @base-padding * 0.5;
}

.dynamicForm .ui.form .timeSelection > .fields {
    margin: 0px
}

.dynamicForm .dynList {
    border: @secondaryBorderWidth solid;
    border-radius: @baseBorderRadius * 6;
}

.dynamicForm .dynList > div:not(.itemSearch) {
    padding: 0px @base-padding * 2;
}

.dynamicForm .timeSelection > .fields {
    height: 32px;
}

.dynamicForm .ui.multiple.search.dropdown > input.search {
    margin: 0px
}

.dynamicForm .ui.form .ui.multiple.search.selection.dropdown > input.search {
    padding: 0px
}

.dynamicForm .ui.multiple.dropdown > .label {
    border: @secondaryBorder;
    border-radius: @baseBorderRadius * 4;
    margin-right: @base-margin;
    margin-top: @base-margin;
    background-color: @green;
    position: relative;
    font-size: @font-size-3xs;
    box-shadow: none;
}

.dynamicForm  .ui.upward.multiple.selection.dropdown .menu {
    border-width: @secondaryBorderWidth!important;
}

.dynamicForm .field.disabled .ui.multiple.dropdown > .label i {
    display: none;
}

/********************************** Comma Separation ******************************************/
// Add commas in between items
.dynamicForm .field.disabled .ui.multiple.dropdown > .label::after  {
    content: ","
}

// Remove comma if not more than 3 items, and if last item
.dynamicForm .field.disabled .ui.multiple.dropdown > .label:first-of-type:nth-last-of-type(2)::after,
.dynamicForm .field.disabled .ui.multiple.dropdown > .label:last-of-type::after,
.dynamicForm .field.disabled .ui.multiple.dropdown > .label:first-of-type:nth-last-of-type(1)::after {
    content: ""
}

// Add and percent to last item
.dynamicForm .field.disabled .ui.multiple.dropdown > .label:last-of-type::before  {
    content: "& "
}

// Remove and percent if only one item
.dynamicForm .field.disabled .ui.multiple.dropdown > .label:first-of-type:nth-last-of-type(1)::before {
    content: ""
}

/***********************************************************************************************/

.dynamicForm .field.disabled .ui.multiple.dropdown > .label {
    border-width: 0px;
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
}

.dynamicForm .dynamicRow .ui.button.saveEditButton,
.dynamicForm .dynamicRow .ui.button.saveEditButton:focus {
    box-shadow: none!important;    
}

.dynamicForm .dynamicRow .ui.button.saveEditButton {
    padding: @base-padding @base-padding * 4;
    height: 32px;
    border: @secondaryBorder;
    margin-left: 0px;
}

.dynamicForm .authMethodField > .field {
    margin: 0px
}