/* ---------- Fonts ---------- */

@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("../fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: url("../fonts/SourceSansPro-SemiBold.ttf");
}

@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("../fonts/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: "Lato-Regular";
    src: url("../fonts/Lato-Regular.ttf");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.ttf");
}

@font-face {
    font-family: "Lato-Black";
    src: url("../fonts/Lato-Bold.ttf");
}