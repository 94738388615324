@import "./variables.less";

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?3nqpla');
    src:  url('../fonts/icomoon.eot?3nqpla#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?3nqpla') format('truetype'),
        url('../fonts/icomoon.woff?3nqpla') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
.icon-building:before {
    font-size: 26px;
    content: "\e900";
}

.icon-monitor1:before {
    font-size: @font-size-xl+2;
    content: "\e901";
}

.icon-monitor1 {
    position: relative;
    top: 4px;
}

.icon-monitor2 {
    position: relative;
    top: 4px;
}

.icon-monitor3 {
    position: relative;
    top: 4px;
}

.icon-monitor4 {
    font-size: @font-size-xl+2;
    position: relative;
    top: 4px;
}

.icon-monitor4.icon {
    top: 2px
}

.icon-pm2 {
    font-size: @font-size-xl+2;
    top: 4px;
}

.icon-monitor2:before {
    font-size: @font-size-xl+2;
    content: "\e902";
}

.icon-monitor3:before {
    font-size: 26px;
    content: "\e903";
}

.icon-monitor4:before {
    content: "\e904";
}

.table.icon-monitor4 {
    margin-top: -(@base-margin)-1;
    font-size: @font-size-xl;
}

.icon-pm2:before {
    font-size: @font-size-xl+2;
    content: "\e905";
}

.icon-monitor4.small{
    font-size: @font-size-2xs;
    position: relative;
    top: @base;
    margin-top: -2px;
}