@import './variables.less';

.aresRadio::before {
    display: block;
    position: absolute;
    content: '';
    z-index: 1;
    transform: none;
    border: none;
    top: 0rem;
    background: @grey;
    box-shadow: none;
    width: 3.5rem;
    height: 1.5rem;
    border-radius: 500rem;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
    left: 0;
    opacity: .5;
}

.aresRadio {
    position: relative;
    display: inline-block;
    backface-visibility: hidden;
    outline: none;
    vertical-align: baseline;
    font-style: normal;
    min-height: 17px;
    font-size: 1rem;
    line-height: 17px;
    min-width: 17px;
}

.aresRadio {
    cursor: auto;
    position: relative;
    display: block;
    padding-left: 1.85714em;
    outline: none;
    font-size: 1em;
}

.aresRadio {
    color: @opaqueBlack;
    transition: color 0.1s ease;
}

.aresRadio {
    min-height: 1.5rem;
    padding-left: 4.5rem;
    color: @grey;
    padding-top: 0.15em;
    cursor: pointer;
    user-select: none;
    background: none;
    font-family: @secondary-font-bold;
    color: @root-white;
}

.aresRadio::after {
    position: absolute;
    font-size: @font-size-2xs;
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    text-align: center;
    opacity: 0;
    color: @opaqueBlack;
    transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
    font-family: 'Checkbox';
    background: @white linear-gradient(transparent, @shadowBlack);
    position: absolute;
    content: '' !important;
    opacity: 1;
    z-index: 2;
    border: none;
    box-shadow: 0px 1px 2px 0 @shadowBlack2, 0px 0px 0px 1px @shadowBlack2 inset;
    width: 1.5rem;
    height: 1.5rem;
    top: 0rem;
    left: 0em;
    border-radius: 500rem;
    transition: background 0.3s ease, left 0.3s ease;
    left: -0.05rem;
    box-shadow: 0px 1px 2px 0 @shadowBlack2;
    font-size: 12px;
    content: '\e801';
}

.checked .aresRadio::before {
    opacity: 0.9;
    background-color: #8a8af5 !important;
}

.checked .aresRadio::after {
    left: 2.15rem;
    box-shadow: 0px 1px 2px 0 @shadowBlack2, 0px 0px 0px 1px @offWhite inset;
}

.aresRadio .fieldLabel {
    margin-bottom: 0px;
}

.aresRadio.left > .fieldLabel {
    position: absolute;
    left: -14px;
    top: 1px;
    height: 100%;
}

.aresRadio.left::before {
    left: 15px
}

.aresRadio.left::after {
    left: calc(-0.05rem + 15px)
}

.checked .aresRadio.left::after {
    left: calc(2.15rem + 13px);
}