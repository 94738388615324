@import './variables.less';

.filterDropdownTheme(@background-color, @text-color) {
    .ui.buttons.filterButtonGroup .ui.mini.button {
        color: @text-color;
        background: transparent;
        border-color: rgb(red(@text-color), green(@text-color), blue(@text-color));
    }

    .ui.buttons.filterButtonGroup .ui.mini.button:hover {
        background: fade(@text-color, 5%);
    }

    .ui.buttons.filterButtonGroup .ui.mini.button.active {
        background: rgb(red(@text-color), green(@text-color), blue(@text-color));
        color: rgb(red(@background-color), green(@background-color), blue(@background-color));
        border-color: rgb(red(@text-color), green(@text-color), blue(@text-color));
        font-family: @primary-font-black;
    }

    .ui.dropdown.dropdown.filterDropdown .menu > .input input {
        color: @text-color;
        border-color: @text-color
    }
    
    .ui.dropdown.dropdown.filterDropdown .menu > .input i{
        color: @text-color;
    }

    .filterDropdown  .ui.selection.dropdown {
        color: @text-color;
        border-color: @text-color
    }

    .ui.selection.visible.dropdown.aresSelect  > .text:not(.default) {
        color: @text-color;
    }
    
    .ui.dropdown.selected, .ui.dropdown.aresSelect  .menu .selected.item {
        background-color: if(luma(@text-color) > 50%, darken(@text-color, 50%), lighten(@text-color, 50%));
        color: @text-color
    }

    .filterDropdown  .ui.selection.dropdown:hover {
        border-color: @text-color;
    }

    .aresSelect .ui.icon.input input {
        border-color: @text-color;
        color: @text-color!important;
    }

    .ui.selection.active.dropdown.aresSelect .menu{
        border-color: @text-color;
    }

    .ui.dropdown.filterDropdown .menu > .divider {
        border-color: @text-color;
    }
    
    .ui.toggle.checkbox.filterToggle  input:checked ~ .box, .ui.toggle.checkbox.filterToggle input:checked ~ label {
        color: @text-color!important
    }
    
    .ui.toggle.checkbox.filterToggle input:focus:checked ~ .box, .ui.toggle.checkbox.filterToggle  input:focus:checked ~ label {
        color: @text-color!important
    }
    
    .ui.checkbox.filterToggle input:focus ~ label {
        color: fade(@text-color, 50%)
    }

    .ui.dropdown.filterDropdown  .menu > .header {
        color: @text-color
    }

    .ui.dropdown.filterDropdown  .menu > .item .header,
    .ui.dropdown.filterDropdown  .menu > .item .header:hover,
    .ui.dropdown.filterDropdown  .menu > .item .header:focus {
        color: @text-color!important;
        opacity: 1;
        cursor: default 
    }
    
    .ui.button.filterBtns {
        border-color: @text-color;
    }

    .ui.toggle.checkbox.filterToggle .box, .ui.toggle.checkbox.filterToggle label {
        color: fade(@text-color,50%)
    }
    
    .pageHeader h1.ui.header a {
        color: @text-color
    }

    .ui.list.dynamicList,
    .ui.divided.list.dynamicList div > .item {
        border-color: @text-color
    }

    .itemSearch .ui.vertical.menu .itemView > div > .item,
    .ui.list.dynamicList div > .item .header, 
    .ui.list.dynamicList div > .item i {
        color: @text-color
    }

    .itemSearch .ui.vertical.menu {
        background-color: @background-color;
        color: @text-color;
        border-color: @text-color
    }
    
    .itemSearch .ui.vertical.menu .itemView > div > .disabled.item {
        color: fade(@text-color, 50%)!important;
    }

    .pageHeader h1.ui.header a {
        color: @text-color
    }

    .ui.list.dynamicList,
    .ui.divided.list.dynamicList div > .item {
        border-color: @text-color
    }

    .itemSearch .ui.vertical.menu .itemView > div > .item,
    .ui.list.dynamicList div > .item .header, 
    .ui.list.dynamicList div > .item i {
        color: @text-color
    }

    .itemSearch .ui.vertical.menu {
        background-color: @background-color;
        color: @text-color;
        border-color: @text-color
    }
    
    .itemSearch .ui.vertical.menu .itemView > div > .disabled.item {
        color: fade(@text-color, 50%)!important;
    }
}

.calendarTheme(@background-color, @text-color, @calendar-active, @calendar-active-enabled, @calendar-main-hover, @calendar-range-hover, @calendar-disabled, @calendar-now) {
    // Calendar buttons
    .react-calendar__tile--active {
        background-color: @calendar-active;
    }

    .react-calendar__tile--active:enabled:hover, 
    .react-calendar__tile--active:enabled:focus {
        background: @calendar-active-enabled;
    }

    .react-calendar__tile:enabled:hover, 
    .react-calendar__tile:enabled:focus {
        background: @calendar-main-hover;
    }

    .react-calendar--selectRange .react-calendar__tile--hover,
    .react-calendar--selectRange .react-calendar__tile--hover {
        background: @calendar-range-hover;
    }

    .aresSelect .react-calendar__navigation button[disabled],
    .aresSelect .react-calendar__tile:disabled {
        background-color: @calendar-disabled !important;
        color: fade(@midGrey, 30%) !important;
    }

    .react-calendar__tile--now {
        background-color: @calendar-now;
    }

    .aresCalendar .dayGrid .day:nth-child(7n),
    .aresCalendar,
    .aresCalendar .dayGrid > .day:nth-child(7n + 1),
    .aresCalendar .dayGrid > .day:nth-child(7n + 2),
    .aresCalendar .dayGrid > .day:nth-child(7n + 3),
    .aresCalendar .dayGrid > .day:nth-child(7n + 4),
    .aresCalendar .dayGrid > .day:nth-child(7n + 5),
    .aresCalendar .dayGrid > .day:nth-child(7n + 6), 
    .aresCalendar .dayGrid > .day:nth-child(43), 
    .aresCalendar .dayGrid > .day:nth-child(49),
    .aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav.monthButton,
    .calendarPicker {
        border-color: @text-color;
    }

    .aresCalendar > .calendarTitle > .title {
        background-color: @background-color;
        color: @text-color
    }

    .aresCalendar > .calendarTitle > .title:hover {
        background-color: @blue;
    }

    .aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav,
    .aresCalendar .dayGrid .day,
    .aresCalendar .monthGrid .month,
    .aresCalendar .yearGrid .year {
        background-color: @background-color;
        color: @text-color
    }

    .aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav:hover {
        background-color: lighten(@grey, 10%);
    }
    
    .aresCalendar .dayGrid > .day[disabled], 
    .aresCalendar .dayGrid .day.hover[disabled],
    .aresCalendar .monthGrid .month[disabled],
    .aresCalendar .yearGrid .year[disabled] {
        background-color: lighten(@grey, 30%);
        color: @text-color;
    }

    .aresCalendar > .calendarTitle .calendarTitleButtons .calendarNav[disabled] {
        background-color: @grey;
    }
    
    .aresCalendar .dayGrid > .day.dayOfWeek,
    .aresCalendar .dayGrid > .day.dayOfWeek:hover {
        background-color: @background-color;
    }

    .aresCalendar .dayGrid .day.outOfRange,
    .aresCalendar .dayGrid .day.outOfRange:hover {
        background-color: lighten(@grey, 10%);
        color: @text-color
    }

    .picker,
    .ui.form .calendarPicker input[type="text"],
    .ui.form .calendarPicker input[type="select"] {
        color: @text-color
    }

    .aresCalendar .dayGrid > .day:hover,
    .aresCalendar .monthGrid > .month:hover,
    .aresCalendar .yearGrid > .year:hover {
        background-color: lighten(@blue, 10%);
        color: @text-color;
    }

    .aresCalendar .dayGrid > .day.selected,
    .aresCalendar .monthGrid > .month.selected,
    .aresCalendar .yearGrid > .year.selected {
        background-color: @blue;
    }
    
    .aresCalendar .dayGrid .day.hover,
    .aresCalendar .dayGrid > .day.betweenSelected {
        background-color: lighten(@blue, 10%); 
    }
    
    .aresCalendar .dayGrid > .day.otherSelected {
        background-color: @blue;
    }
}

.modalTheme(@background-color, @modal-background-color, @modal-text, @modal-input-background, @background-image) {
    /*-------------------
      Ares Modal Header
    --------------------*/
    .ui.modal.aresModal > .header {
        background-color: @modal-background-color;
        color: @modal-text;
        border-color: @modal-text;
    }

    .ui.modal.aresModal > .header .closeButton > i:hover {
        color: fade(@modal-text, 80%)
    }
    
    /*-------------------
      Ares Modal Body
    --------------------*/
    .ui.modal.aresModal > .content {
        background-color: @modal-background-color;
        color: @modal-text;
        border-color: @modal-text;
    }

    .ui.modal.aresModal .content > div {
        color: @text-color
    }
    
    /*-------------------
      Ares Modal Foot
    --------------------*/
    .ui.modal.aresModal > .actions {
        background-color: @modal-background-color;
        color: @modal-text;
        border-color: @modal-text;
    }

    /*-------------------
      Ares Modal Tab
    --------------------*/
    .aresModalMenuItem.ui.compact.inverted.menu a {
        border-color: @modal-text;
        color: @modal-text;
    }
    
    .aresModalMenuItem.ui.compact.inverted.menu a.active.item {
        @is-bg-dark: boolean(luma(@background-color) > 50%);
        background: if(@is-bg-dark, darken(@background-color, 20%), lighten(@background-color, 20%));
        color: @modal-text!important;
    }

    /*-------------------
      Ares Modal Interfacing
    --------------------*/
    .ui.modal.aresModal .interfaceContainer {
        color: @modal-text!important;
    }
    
    .ui.modal.aresModal .interfaceSettings {
        border-color: @modal-text;
    }

    /*-------------------
      Ares Modal Button
    --------------------*/
    .ui.modal.aresModal .ui.button {
        color: @modal-text;
        border-color: @modal-text;
    }

    /*-------------------
      Ares Modal Divider
    --------------------*/
    .ui.modal.aresModal hr {
        border-color: @text-color;
        border-bottom-color: @text-color;
    }

    .ui.message.errorLabel {
        background-color: @modal-background-color;
    }

    /*-------------------
      Object Builder
    --------------------*/
    .ui.modal.aresModal .ui.button.addObjectButton:hover,
    .ui.modal.aresModal .ui.button.addObjectButton:active,
    .ui.modal.aresModal .ui.button.addObjectButton:focus,
    .ui.modal.aresModal .ui.button.addObjectButton.active {
        background: fade(@text-color, 20%)
    }    

    .objectBuilder .previewMode {
        border-color: @modal-text;
    }

    .objectBuilder .title > i.fa-eye-slash {
        color: fade(@text-color, 20%);
    }   

    .objectBuilder .title > i:hover {
        color: fade(@text-color, 70%);
    }   
    
    .objectBuilderItem .deleteButton > i:hover {
        @is-bg-dark: boolean(luma(@background-color) > 50%);
        color: if(@is-bg-dark, darken(@background-color, 50%), lighten(@background-color, 50%));
    }
    
    .objectBuilderItem .objectBuilderItemTag {
        @is-bg-dark: boolean(luma(@background-color) > 50%);
        background-color: if(@is-bg-dark, darken(@background-color, 20%), lighten(@background-color, 20%));
    }

    .objectBuilderItem .objectBuilderItemTag {
        border-color: @text-color;
    }

    .objectBuilderItem .objectBuilderItemTag:hover {
        @is-bg-dark: boolean(luma(@background-color) > 50%);
        background-color: if(@is-bg-dark, darken(@background-color, 40%), lighten(@background-color, 40%));
    }

    .objectBuilderItem .objectBuilderItemTag.active:hover {
        background-color: @purple;
    }

    .objectBuilderItem .objectBuilderItemTag.active {
        background-color: @purple;
    }
    
    .objectBuilderItem .objectBuilderItemTag.active.error,
    .objectBuilderItem .objectBuilderItemTag.error,
    .objectBuilderItem .objectBuilderItemTag.active.error:hover,
    .objectBuilderItem .objectBuilderItemTag.error:hover {
        background-color: @lightRed;
    }

    /*-------------------
      Preview Mode Colors
    --------------------*/
    .previewMode .key {
        color: @text-color; 
    }

    /*-------------------
    Ares Modal Field Multi Input
    --------------------*/
    .ui.mult > .multiInput {
        color: @text-color;
    }

    .ui.mult {
        border-color: @text-color;
    }

    .aresModal .ui.multiple.dropdown > .label {
        border-color: @text-color;
        color: @text-color
    }
}

.siteTheme(@background-color, @top-bar-color, @alpha-bg, @text-color, @blend:false, @gray: gray, @background-color-Img, @nav-color, @data-view-gray, @info-card-background-color, @buttonBackground, @hoverTextColor, @hoverBackgroundColor, @tableBackgroundColor, @selectionColor, @dropdownHover, @weatherBackgroundColor, @otherBackgroundColor) {
    & {
        background-color: @top-bar-color;
    }

    .fillContainer {
        color: @text-color;
    }

    ::selection {
        background: @selectionColor; /* WebKit/Blink Browsers */
    }
    
    ::-moz-selection {
        background: @selectionColor; /* Gecko Browsers */
    }
    
    .ui.header {
        color: @text-color;
    }

    .imageHolder {
        background: @background-color-Img;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .bg {
        background-color: @alpha-bg;
    }
    
    .glass {
        background: inherit;
    }
    
    .glass::before {
        background: inherit;
    }

    .transparent {
        mix-blend-mode: if(not (@blend), hard-light, unset);
        background-color: @alpha-bg;
    }

    .navMenu-title {
        color: @text-color;
    }

    .vSpacer {
        background-color: @text-color;
    }

    .pathText,
    .pathTextMobile,
    .pathTextMobile a,
    .parentSiteTrigger,
    .subsiteTrigger {
        color: @text-color;
    }

    .crumb {
        color: @text-color;
    }
    
    .navMenu-menuBtn {
        color: @text-color;
    }

    .settingsMenu {
        border-color: @text-color;
        color: @text-color;
        background-color: if(not (@blend), @settingsGray, transparent);
    }

    .settingsMenu:hover {
        background-color: @settingsButtonBackground;
    }
    
    .contentHeader {
        mix-blend-mode: if(not (@blend), hard-light, unset);
    }

    .contentTransparent {
        background-color: @alpha-bg;
        mix-blend-mode: if(not (@blend), hard-light, unset);
        color: @text-color
    }

    .contentSolid {
        color: @text-color
    }

    .pageHeader h1.ui.header {
        color: @text-color;
    }

    .viewBar {
        color: @text-color;
    }

    .viewFilter {
        border-color: @text-color;
        background-color: @buttonBackground;
        color: @text-color;
    }

    .viewFilter:hover {
        background-color: @hoverBackgroundColor;
        color: @hoverTextColor;
    }

    .fillContainer.row > .fillLine > .line,
    .fillContainer.column > .fillLine > .line {
        background-color: @gray;
    }

    .dataView .viewToggle > i.active {
        color: @text-color;
    }

    .mainHeaderBar {
        border-color: @text-color;
    }
    
    .navigationMenu {
        border-color: @text-color;
    }

    .cardBox {
        background-color: if(not (@data-view-gray=false), @data-view-gray, @background-color);
        color: @text-color;
        border-color: @text-color;
    }

    .cardBox.infoCard {
        background-color: @gray;
        color: @info-card-background-color;
        border-color: @info-card-background-color;
    }

    .cardBox.infoCard:hover {
        // background-color: lighten(@background-color, 10%);
        background-color: darken(@gray, 5%);
        color: @info-card-background-color;
        border-color: @info-card-background-color;
    }

    .ui.loader.infoCardLoader:after,
    .ui.dimmer .ui.loader.infoCardLoader:after {
        border-color: @info-card-background-color transparent transparent;
    }

    .navMenu-item {
        color: if(not (@nav-color=false), @nav-color, @background-color);
    }

    .navMenu-logo .logo{
        fill: @text-color
    }

    .passwordTable.ui.table {
        color: @text-color;
    }

    .setPasswordButton.ui.button {
        color:@text-color;
        box-shadow: 0px 0px 0px 3px  @text-color inset !important;
    }

    .dashInfo {
        color: @text-color
    }

    .recent {
        color: @text-color
    }
    
    * {
        /* Handle */
        &::-webkit-scrollbar-thumb {     
            background: @text-color!important;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: @text-color!important;
        }

        &::-webkit-scrollbar-track {
            background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 80%), lighten(fadein(@text-color, 100%), 80%))!important;
        }
    }

    @-moz-document url-prefix() {
        * {
          scrollbar-color: @text-color transparent;
          scrollbar-width: thin;
        }
    }

    .sectionTitle, .refreshStatus {
        color: @text-color;
    }

    .mainHeaderBarLeft.bg,
    .mainHeaderBarLeft.transparent,
    .mainHeaderBarRight.bg,
    .mainHeaderBarRight.transparent,
    .mainHeaderBarMiddle.bg,
    .mainHeaderBarMiddle.transparent {
        background-color: @top-bar-color;
    }

    .systemMessage td:last-child {
        color: @text-color;
    }

    .aresRadio {
        color: @text-color;
    }

    .weatherInfo {
        color: @text-color;
        border-color: @text-color;
        background-color: @weatherBackgroundColor;
    }

    .miscInfo {
        color: @text-color;
        border-color: @text-color;
        background-color: @otherBackgroundColor;
    }

    .weatherInfo hr, .miscInfo hr {
        color: @text-color;
        background-color: @text-color;
    }

    .miscInfo .data > a {
        color: @text-color;
        &:hover {
            color: @hoverTextColor
        }
    }

    .faOff::after {
        -webkit-text-stroke: 1px @text-color;
        text-shadow: -1px 1px 0px @background-color;
    }

    .ui.button.applyReportDate {
        background-color: @background-color;
    }

    .authOption > .authStatus,
    .ui.button.authMethodButton {
        border-color: @text-color;
    }

    .ui.button.authMethodButton:hover {
        background-color: @hoverBackgroundColor;
    }
}

.dataView(@background-color, @text-color, @gray, @buttonBackground, @hoverTextColor, @hoverBackgroundColor, @tableBackgroundColor, @dropdownBackground, @dropdownHover, @toggleColor) {
    .dataViewHeader .fillHeaderRight > .viewBarActions i:hover {
        color: fade(@text-color, 50%);
    }

    .tableView {
        border-color: @text-color;
        background: @tableBackgroundColor;
    }

    .tableView table {
        color: @text-color;
    }

    .tableView tr:first-child,
    .tableView tr th:last-child,
    .tableView tr th:first-child  { 
        background-color: if(not (@data-view-gray=false), @data-view-gray, shade(@background-color, 21.5%));
    }

    .dataView .tableFoot .row {
        background: if(not (@data-view-gray=false), @data-view-gray, shade(@background-color, 21.5%));
        border-color: @text-color;
    }

    .tableWrapper {
        border-color: @text-color
    }

    .tableView tbody {
        border-color: @text-color;
    }

    .tableView th, .tableView tr, .tableView td {
        border-color: @text-color;
    }

    .tableView > .tableFoot .row {
        background-color: if(not (@data-view-gray=false), @data-view-gray, shade(@background-color, 21.5%));
        border-color: @text-color;
    }
    
    .pageNumBtn {
        color: @text-color;
        border-color: @text-color;
    }

    .pageNumBtn.active {
        color: fade(@text-color, 70%);
        border-color: fade(@text-color, 70%);
    }

    .pageNumBtn.disabled {
        color: fade(@text-color, 30%);
        border-color: fade(@text-color, 30%);
    }

    .ui.dropdown .menu {
        background-color: @dropdownBackground;
        border-color: @text-color;
    }

    .ui.vertical.topBarMenu {
        background-color: @dropdownBackground;
        border-color: @text-color;
    }

    .ui.topBarMenu.menu .item {
        color: @text-color;
    }

    .ui.topBarMenu.menu .item:hover {
        background-color: @dropdownHover;
        color: @text-color;
    }

    .ui.dropdown .menu > .item {
        color: @text-color;
    }

    .visible.menu.transition .item:hover {
        background: @dropdownHover;
        color: @text-color;
    }

    .ui.button.saveEditButton, 
    .ui.button.saveEditButton:focus {
        background-color: @buttonBackground;
        box-shadow: 0px 0px 0px 3px @text-color inset !important;
        color: @text-color
    }

    .ui.button.saveEditButton:hover {
        background-color: @hoverBackgroundColor;
        color: @hoverTextColor;
    }

    .ui.button {
        color: @text-color;
        border-color: @text-color;
    }

    .ui.button:hover {
        color: @hoverTextColor;
    }

    .ui.tiny.button.exportButton,
    .ui.tiny.button.importButton {
        background: @dropdownBackground;
        color: @text-color;
    }

    .ui.tiny.button.exportButton:hover,
    .ui.tiny.button.importButton:hover {
        background: @dropdownHover;
        color: @hoverTextColor;
    }

    .ui.checkbox.exportCheckbox label {
        color: @text-color;
    }

    .ui.checkbox.exportCheckbox input:checked ~ .box:before,
    .ui.checkbox.exportCheckbox input:checked ~ label:before {
        background-color: @toggleColor !important;
        border: @toggleColor 2px solid;
    }

    .ui.checkbox.exportCheckbox .box:before, 
    .ui.checkbox.exportCheckbox label:before {
        border: @text-color 2px solid;
    }

    .ui.disabled.checkbox.exportCheckbox .box::before,
    .ui.disabled.checkbox.exportCheckbox label::before {
        background-color: fade(@text-color, 70%) !important;
    }
    .ui.disabled.checkbox.exportCheckbox .box:hover::before,
    .ui.disabled.checkbox.exportCheckbox label:hover::before {
        border: @text-color 2px solid !important;
    }

    .dataView .viewToggle > i {
        color: fade(@text-color, 25%)
    }

    .viewer .ui.dimmer {
        background-color: fade(@background-color, 0%);
    }

    .ui.loader:after,
    .ui.dimmer .ui.loader:after {
        border-color: @text-color transparent transparent;
    }

    .cardBox > .title > .ui.checkbox.select .box:before, .cardBox > .title > .ui.checkbox.select label:before {
        border-color: @text-color;
        color: @text-color ;
    }

    .cardBox > .title > .ui.checkbox.select .box:after, .cardBox > .title > .ui.checkbox.select label:after {
        color: @text-color;
    }

    .tableView i.fas.fa-ellipsis-h:hover,
    .cardBox i.fas.fa-ellipsis-v:hover {
        color: fade(@text-color, 80);
    }

    .tableView tr:not(:first-child):not(:last-child):hover {
        background-color: if(not (@data-view-gray=false), fade(@data-view-gray, 100%), fade(shade(@background-color, 15%), 100%));
    }

    .tableView tr:not(:first-child):not(:last-child):hover td:first-child,
    .tableView tr:not(:first-child):not(:last-child):hover td:last-child {
        background-color: if(not (@data-view-gray=false), fade(@data-view-gray, 100%), fade(shade(@background-color, 15%), 100%));
    }

    .tableView tr td:first-child,
    .tableView tr td:last-child {
        background: @tableBackgroundColor;
    }

    .viewer .noResults {
        color: @gray;
    }

    .aresPopUp {
        border-color: @text-color;
        color: @text-color;
        background-color: @dropdownBackground;
    }    

    .detailedBox {
        background-color: if(not (@data-view-gray=false), @data-view-gray, @background-color);
        color: @text-color;
        border-color: @text-color;
    }

    .detailedBox.active {
        border-color: @purple;
    }

    .detailedBox.detailCard {
        border-color: @purple;
    }

    .detailedBox > .circle {
        color: @text-color;
    }

    .detailedBox > .detailedMenu {
        background-color: @dropdownBackground;
        color: @text-color;
        border-color: @text-color;
    }

    .supportEmailLink {
        color: @text-color;
    }

    .scheduleCard > div:first-child,
    .scheduleCard > div:last-child {
        border-color: @text-color;
    }

    .scheduleBarMenu .scheduleBarActions .ui.button.saveEditButton:hover {
        background-color: @text-color;
        color: @background-color;
    }

    .scheduleCard table tbody > tr:first-child {
        background-color: @background-color;
    }

    .scheduleCard > div:last-child {
        background-color: @background-color;
    }

    .scheduleCard table tbody > tr:first-child td {
        border-color: @text-color;
    }

    .subMenu.menu > .item.backItem {
        background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 80%), lighten(fadein(@text-color, 100%), 80%));
    }

    .dynamicForm .ui.multiple.dropdown > .label {
        border-color: @text-color;
        color: @text-color
    }

    .dynamicForm .dynamicRow .ui.button.saveEditButton {
        border-color: @text-color;
    }
}

.dynamicList(@background-color, @text-color) {
    .itemSearch .ui.input > i {
        color: @text-color;
    }

    .dynList {
        border-color: @text-color;
    }
    
    .viewFilter .dynList > .itemSearch .field .ui.input input {
        color: @text-color;
    }

    .dynList.dynInput > .itemSearch .field .ui.input input {
        color: @text-color;
    }

    .dynList.dynInput {
        border-color: @text-color;
    }

    .dynList.dynInput .tag {
        border-color: @text-color;
    }
}

.ratings(@background-color, @text-color, @gray) {
    .starRating > .title {
        color: @text-color;
    }

    .starRating i > span {
        color: @text-color
    }

    .issues > .title {
        color: @text-color;
    }

    .insight .list {
        color: @text-color;
    }

    .insight .listHolder .ui.list .listSpacer {
        background-color: @text-color;
    }   

    .insight .allGood {
        color: @gray
    }

    .mobileRatingToggle, 
    .mobileRatingToggle i {
        color: fade(@text-color, 20%);
    }

    .mobileRatingToggle span:hover, 
    .mobileRatingToggle i:hover {
        color: fade(@text-color, 70%);
    }

    .mobileRatingToggle, 
    .mobileRatingToggle i.active {
        color: @text-color;
        border-color: @text-color
    }

    .ui.top.center.popup.infoPopUp {
        background-color: @background-color;
        color: @text-color;
        border-color: @text-color
    }
}

.mobileOverides(@background-color, @text-color) {
    @media (max-width: 850px) {
        .navigationMenu {
            background-color: @background-color;
            
        }
        
        .navMenu-item.active {
            background-color: fade(@text-color, 25%)
        }
    }
}

.chartTheme(@background-color, @text-color) {
    .chart-title {
        color: @text-color;
    }
    
    .recharts-label text > tspan {
        fill: @text-color;
    }

    .svgButton.active text > tspan {
        fill: @background-color
    }

    .recharts-text.recharts-label {
        fill: @text-color;
        stroke: @text-color;
    }

    .tabItems.ui.compact.inverted.menu .active.item {
        background: fade(@text-color, 50%);
        color: @text-color!important
    }

    .tabItems.ui.compact.inverted.menu .item {
        border-color: @text-color;
        color: @text-color
    }

    .ui.mini.buttons.legend-container {
        border-color: @text-color;
    }

    .ui.mini.buttons.legend-container .ui.button {
        color: @text-color;
        border-color: @text-color;
    }

    .recharts-text.recharts-cartesian-axis-tick-value {
        stroke: @text-color
    }

    .recharts-cartesian-grid-horizontal line {
        stroke: @text-color
    }
    
    .recharts-cartesian-grid-vertical line {
        stroke: @text-color;
    }
    
    .recharts-cartesian-axis-line{
        stroke: @text-color
    }

    .xAxisTickLabel {
        stroke: @text-color
    }

    .back-button-visible.true.ui.inverted.button {
        box-shadow: 0px 0px 0px @primaryBorderWidth @text-color inset !important;
        background: @background-color;
        color: @text-color;
    }
    
    .back-button-visible.true.ui.inverted.button:focus {
        background: @background-color;
        color: @text-color;
        box-shadow: 0px 0px 0px @primaryBorderWidth @text-color inset;      
    }

    .recharts-custom-tooltip {
        background-color: @background-color;
    }

    .recharts-custom-tooltip .tooltip-label {
        color: @text-color;
    }

    .ui.button.viewAlertsButton {
        border-color: @text-color;
        color: @text-color;
        background-color: @background-color;
    }

    .ui.button.viewAlertsButton:hover {
        background-color: fade(@text-color, 40%);
    }
}

.barsAndGauges(@background-color, @text-color, @gauge-background) {
    .barValue {
        fill: @text-color
    }
    
    .barsTitle {
        color: @text-color;
    }

    .barTitle {
        fill: @text-color
    }

    .gaugeTitle,
    .gaugeThresh {
        fill: @text-color
    }

    .gaugeIcon { 
        stroke: @text-color
    }

    .gaugeBackground {
        stroke: @gauge-background !important;
    }

    .barBackground {
        stroke: @gauge-background !important;
    }
}

.reportsCardsTheme(@background-color, @text-color, @useGrayCard, @hoverTextColor) {
    .reports-card {
        border: @primaryBorderWidth @text-color solid;
        color: @text-color;
        background-color: if(not (@useGrayCard=false), @useGrayCard, @background-color);
    }

    .icon-divider {
        background: @text-color;
    }

    .tricorn-divider line {
        stroke: @text-color;
    }

    .card-timeframe-container a {
        color: @text-color;
        :hover {
            color: @hoverTextColor;
        }
    }
}

.accordionTheme(@text-color) {
    .ui.accordion .title:not(.ui) {
        color: @text-color
    }
}

.pdfButtonTheme(@background-color, @text-color) {
    .ui.button.pdfButton {
        border-color: @text-color;
    }

    .ui.button.pdfButton { 
        background-color: @background-color;  
        border-radius: @baseBorderRadius*4+2;
        color: @text-color;
        border: @primaryBorderWidth @text-color solid;
    }
}

.systemMessageTheme(@critical-message, @info-message, @major-message) {
    .systemMessage.critical {
        color: @critical-message;
        border-color: @critical-message;
    }
    
    .systemMessage.major {
        color: @info-message;
        border-color: @info-message;
    }
    
    .systemMessage.info {
        color: @major-message;
        border-color: @major-message;
    }
}

.sliderTheme(@text-color, @background-color) {
    .ui.slider .rc-slider {
        border-color: @text-color;
        overflow: hidden;
    }
    
    .ui.slider .rc-slider .rc-slider-handle {
        background-color: @text-color
    }
}

.formTheme(@text-color, @background-color) {
    .ui.form input[type="text"],
    .ui.form input[type="number"],
    .ui.form input[type="password"],
    .ui.form textarea,
    .ui.form input[type="text"]:focus,
    .ui.form input[type="number"]:focus,
    .ui.form input[type="password"]:focus,
    .ui.form textarea:focus  {
        color: @text-color;
        border-color: @text-color;
    }

    .ui.form input[type="text"]:disabled {
        -webkit-text-fill-color: @text-color;
    }
    
    .ui.form .field .qrButton {
        color: @text-color;
        border-color: @text-color;
        border-width: @secondaryBorderWidth;
        background: transparent;
    }

    .ui.form .field .qrButton:hover {
        color: fade(@text-color, 70%);
    }

    .qrContainer {
        border-color: @text-color;
        background: @background-color;
    }

    .qrContainer i {
        color: @text-color;
    }
    
    .qrContainer i:hover {
        color: fade(@text-color, 70%)
    }
    
    .ui.active.search.dropdown input.search:focus + .text {
        color: @text-color!important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        -webkit-text-fill-color: @text-color !important;
        border-color: @text-color !important;
    }

    .ui.form .field.field input:-webkit-autofill,
    .ui.form .field.field input:-webkit-autofill:hover,
    .ui.form .field.field input:-webkit-autofill:focus,
    .ui.form .field.field input:-webkit-autofill:active,
    .ui.form .field.field input:-webkit-autofill::first-line {
        -webkit-text-fill-color: @text-color !important;
        border-color: @text-color !important;
    }

    .ui.form .ui.input > input::-webkit-input-placeholder {
        color: @text-color;
        opacity: .6;
    }

    .ui.form .ui.input > input::-moz-placeholder {
        color: @text-color;
        opacity: .6;
    }

    .ui.form .ui.input > input[type=number]::-webkit-inner-spin-button { 
        filter: brightness(.1);
    }

    .ui.form .ui.input > .spinner {
        color: @text-color;
        border-color: @text-color;
    }

    .ui.form .ui.input > .spinner i:hover { 
        color: fade(@text-color, 50%);
    }
    
    .ui.form .field.countryCode > div {
        border-color: @text-color;
    }
      
    .ui.form .countryCode .ui.selection.active.dropdown .menu {
        border-color: @text-color;
    }

    .ui.form .field.phoneField .phoneInputClear {
        color: @text-color;
    }

    .ui.form .field.error .ui.dropdown .text {
        color: @text-color;
    }


    .ui.form .ui.disabled.input > input::-webkit-input-placeholder {
        opacity: 0;
    }
    
    .ui.form .ui.disabled.input > input::-moz-placeholder {
        opacity: 0;
    }


    .ui.form .timeSelection .fields,
    .timeSelection .fields {
        border-color: @text-color;
    }

    .ui.input {
        color: @text-color
    }
}

.dropdownTheme(@text-color, @background-color) {
    .ui.selection.dropdown {
        color: @text-color;
        border-color: @text-color;
    }
    
    .ui.selection.active.dropdown .menu {
        border-color: @text-color;
    }

    .ui.vertical.menu {
        background-color: fade(@background-color, 100%);
        color: @text-color;
        border-color: @text-color
    }

    .ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text {
        color: @text-color;
        opacity: .6 
    }

    .ui.dropdown .menu > .header {
        color: @text-color;
    }
}

.carouselTheme(@text-color) {
    .carousel {
        color: @text-color
    }

    .carousel > div > i[disable="true"] {
        color: fade(@text-color, 20%);
    }
    
    .carousel > div > i[disable="false"]:hover {
        color: fade(@text-color, 50%);
        cursor: pointer
    }

    .carousel .ui.menu.carousel-dots .item {
        color: fade(@text-color, 20%);
    }

    .carousel .ui.menu.carousel-dots .item.active {
        color: @text-color;
    }
    
    .carousel .ui.menu.carousel-dots .item:hover {
        color: fade(@text-color, 70%);
    }

    .carousel .ui.menu.carousel-dots .item:focus {
        color: @text-color;
    }
}

.stepTheme(@text-color, @background-color) {
    .ui.steps {
        border-color: transparent;
    }

    .ui.steps .step.active {
        //background-color: if(luma(@text-color) > 50%, darken(@text-color, 100%), lighten(@text-color, 100%))
        background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 50%), lighten(fadein(@text-color, 100%), 50%))
    }
    
    .ui.steps .step.active:after {
        //background-color: if(luma(@text-color) > 50%, darken(@text-color, 100%), lighten(@text-color, 100%))
        background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 50%), lighten(fadein(@text-color, 100%), 50%))
    }

    .ui.steps .disabled.step {
        //background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 50%), lighten(fadein(@text-color, 100%), 50%))
        background-color: if(luma(@text-color) > 50%, darken(@text-color, 100%), lighten(@text-color, 100%))
    }

    .ui.steps .disabled.step:after {
        //background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 50%), lighten(fadein(@text-color, 100%), 50%))
        background-color: if(luma(@text-color) > 50%, darken(@text-color, 100%), lighten(@text-color, 100%))
    }

    .ui.steps .step.completed {
        background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 80%), lighten(fadein(@text-color, 100%), 80%))
    }
    
    .ui.steps .step.completed:after {
        background-color: if(luma(@text-color) > 50%, darken(fadein(@text-color, 100%), 80%), lighten(fadein(@text-color, 100%), 80%))
    }

    .ui.modal.aresModal.wizardModal > .content .ui.steps .completed.step .title {
        color: @green
    }
}

.svg(@text-color, @background-color) {
    .svgButton {
        path {
            stroke: @text-color;
            fill: transparent;
        }
            
        text {
            fill: @text-color
        }

        
    }
    
    .svgButton.active {
        pointer-events: none;

        path {
            fill: @text-color;
        }

        text {
            fill: @background-color
        }
    }
    
    .svgButton:hover {
        cursor: pointer;

        path {
            fill: fade(@text-color, 30%);
        }

        /* text {
            fill: @background-color
        } */
    }
}

.theme(
        // Background Color
        @background-color, 
        // Text Color
        @text-color,
        // Top bar color
        @topBarColor,
        // Modal Field Background
        @modal-input-background, 
        // Should not use blending mode
        @blend:false, 
        // Use gray (Use gray when blend is false, otherwise specify your own color)
        @gray: gray,
        // Alpha percentage to use on background color
        @alpha: 85%, 
        // Background image, use default image if not set
        @background-image: @bgImg no-repeat center center fixed, 
        // Navigation item color, default to false to not use
        @nav-color: false,
        // Gauge background color
        @gauge-background,
        // DataView Gray
        @data-view-gray,
        // Use text color for info card, default uses background color
        @info-card-background-color,
        // Calendar active
        @calendar-active,
        // Calendar active enabled
        @calendar-active-enabled,
        // Calendar Main Hover
        @calendar-main-hover,
        // Calendar Range Hover
        @calendar-range-hover,
        // Calendar disabled
        @calendar-disabled,
        // Calendar now
        @calendar-now,
        // Critical System Message Color
        @critical-message,
        // Info System Message Color
        @info-message,
        // Major System Message Color
        @major-message,
        // Modal Background
        @modal-background-color,
        // Button Background Color
        @buttonBackground,
        // Hover text color
        @hoverTextColor,
        // Hover background color
        @hoverBackgroundColor,
        // Table background color
        @tableBackgroundColor,
        // Selection Color
        @selectionColor,
        // Dropdown Background Color
        @dropdownBackground,
        // Dropdown Hover Background Color
        @dropdownHover,
        // Toggle Color
        @toggleColor,
        // Weather card background color
        @weatherBackgroundColor,
        // Other info background color
        @otherBackgroundColor,
    ) {
    @alpha-bg: fade(@background-color, @alpha);
    // Site theme
    .siteTheme(@background-color, @topBarColor, @alpha-bg, @text-color, @blend, @gray, @background-image, @nav-color, @data-view-gray, @info-card-background-color, @buttonBackground, @hoverTextColor, @hoverBackgroundColor, @tableBackgroundColor, @selectionColor, @dropdownHover, @weatherBackgroundColor, @otherBackgroundColor);
    
    // Modals theme
    .modalTheme(@background-color, @modal-background-color, @text-color, @modal-input-background, @background-image);
    
    // Dataview theme
    .dataView(@background-color, @text-color, @gray, @buttonBackground, @hoverTextColor, @hoverBackgroundColor, @tableBackgroundColor, @dropdownBackground, @dropdownHover, @toggleColor);
    
    // Filter dropdown theme
    .filterDropdownTheme(@background-color, @text-color);
    
    // Dynamic List Theme
    .dynamicList(@background-color, @text-color);
    
    // Ratings Theme
    .ratings(@background-color, @text-color, @gray);
    
    // Chart Theme
    .chartTheme(@background-color, @text-color);
    
    // Report Cards Theme
    .reportsCardsTheme(@background-color, @text-color, @data-view-gray, @hoverTextColor);
    
    // Accordion Theme
    .accordionTheme(@text-color);

    // Mobile Overrides
    .mobileOverides(@background-color, @text-color);
    
    // Bars and Gauges Theme
    .barsAndGauges(@background-color, @text-color, @gauge-background);
    
    // PDF Button Theme
    .pdfButtonTheme(@background-color, @text-color);
    
    // Calendar Theme
    .calendarTheme(@dropdownBackground, @text-color, @calendar-active, @calendar-active-enabled, @calendar-main-hover, @calendar-range-hover, @calendar-disabled, @calendar-now);

    // System Messagages Theme
    .systemMessageTheme(@critical-message, @info-message, @major-message);
    
    // Slider Theme
    .sliderTheme(@text-color, @background-color);
    
    // Semantic Form Theme
    .formTheme(@text-color, @background-color);
    
    // Semantic Dropdown Theme
    .dropdownTheme(@text-color, @background-color);

    // Carousel Theme
    .carouselTheme(@text-color);

    .stepTheme(@text-color, @background-color);

    .svg(@text-color, @background-color)
}