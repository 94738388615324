@logoHeight: 40px;

.logoOuter {
    display: flex;
    flex-direction: column;
}

.logoOuter.medium {
    @logoHeight: 60px;

    .eotText {
        font-size: @logoHeight * .7;
        top: -21px
    }

    .liveText {
        font-size: @logoHeight * .45;
        letter-spacing: 4px;
        margin-top: -32px;
    }
}

.logoOuter.small {
    @logoHeight: 40px;

    .eotText {
        font-size: @logoHeight * .7;
        top: -7px
    }

    .liveText {
        font-size: @logoHeight * .45;
        letter-spacing: 3px;
        margin-top: -14px;
    }
}

.logoOuter .logoBox {
    display: flex;
    justify-content: center;
}

.logoOuter .eotText {
    line-height: unset;
    text-align: center;
    position: relative;
}

.logoOuter .liveText {
    line-height: unset;
    line-height: unset;
    text-align: center;
    
}