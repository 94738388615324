.headerText {
    position: relative;
    float: left;
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: rgba(240, 240, 255, 1.0);
    
    top: 10px;
}

.headerSettings {
    float: right;
    font-family: SourceSansPro-SemiBold, Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.95);
    font-size: 16px;
    padding: 4px 12px;
    height: 34px;
    border-radius: 17px;
    border-style: solid;
    border-width: 3px;
    border-color: rgba(255, 255, 255, 0.95);
    background-color: rgba(100, 100, 100, 0.9);
    margin-top: 12px;
    margin-right: 12px;
}

