@import "./variables.less";

.dataView {
    display: inherit;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

/******* DataViewer *******/
@viewer-margin: @fillContentPadding;

.dataView .viewer {
    padding-left:  @viewer-margin;
    padding-right: @viewer-margin;
}

.dataView > .fillContainer.column > .fillContent {
    padding-left: 0px;
    padding-right: 0px;
}

/****** DataViewer - Loader ******/
.viewer .ui.active.loader {
    position: relative;
}

.viewer .overl {
    position: fixed;
    top: @topBarHeight;
    left: 0px;
    margin-left: @navBarWidth + (2*@viewer-margin);
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 100;
    transition: @transitionNav;
}

.overl.inactive {
    margin-left: 0px;
}

/****** DataViewer - DataView Header ******/
.dataViewHeader > .fillHeaderLeft {
    display: flex;
}

.dataViewHeader > .fillHeaderLeft > .title {
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    margin-right: @base-margin;
}

.dataViewHeader > .fillHeaderLeft > .title > .resultCount {
    margin-left: @base-margin;
}

.dataViewHeader > .fillHeaderRight {
    z-index: 10000001;
}

.dataViewHeader > .fillHeaderRight > .viewBarActions {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: @base-margin*4;
}

.dataViewHeader > .fillHeaderRight > .viewBarActions i.fa-pen-to-square {
    margin-top: -3px;
}

.dataViewHeader > .fillHeaderRight > .viewBarActions i:hover {
    color: @main-white;
    cursor: pointer;
}

.dataViewHeader > .fillHeaderRight > .viewBarActions i {
    margin-left: @base-margin * 2;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle {
    position: relative;
    bottom: 2px;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    font-size: @font-size-l;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > i {
    position: relative;
    top: 3px;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > i[disabled] {
    pointer-events: none;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > i.icon {
    margin: unset;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > .icon-monitor4 {
    font-size: unset;
    top: 4px;
}

.dataViewHeader > .fillHeaderLeft > .viewToggle > i {
    position: relative;
    color: @grey
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > i.icon {
    width: 25px;
}

.dataViewHeader > .fillHeaderLeft >  .viewToggle > i:hover {
    opacity: .5;
    cursor: pointer;
}

.dataViewHeader > .fillHeaderLeft > .viewToggle > i.active {
    background: none;
    color: @root-white;
    cursor: pointer;
}

.viewToggle .toggleDivider {
    margin-left: @base-margin + 2;
    margin-right: @base-margin + 2;
}

/*********** DataViewer - Filter *********/
.viewFilter {
    position: relative;
    float: right;
    font-family: @primary-font-bold;
    letter-spacing: 1px;
    color: @main-white;
    font-size: @font-size-s;
    border-radius: @baseBorderRadius*4+2;
    border: @primaryBorderWidth @main-white solid;
    background-color: @pageBackground;  
    margin-right: 0px;
    transition: .5s;
    padding-left: @base-padding * 4;
    padding-right: @base-padding * 4;
    padding-top: @base-padding;
    padding-bottom: @base-padding;
    top: -1px;
    z-index: 10000001;
    pointer-events: all;
}

.linechart .viewFilter.charts {
    width: max-content;
}

.viewFilter .ui.dropdown .menu > .header {
    color: @root-white;
}

.viewFilter .fas.fa-filter {
    font-size: @font-size-2xs;
}

.viewFilter i.dropdown.icon {
    font-size: @font-size-s!important;
    margin: 0em 0em 0em 0em!important;
    margin-left: @base-margin!important;
    padding-top: 0px;
}

.viewFilter .ui.dropdown .menu {
    z-index: 1000000000000;
}

/*********** Card View ****************/
.cardView {
    height: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.cardViewLoading {
    filter: brightness(.4);
    pointer-events: none;
}

.statusTrue {
    color: @green;
}

.statusFalse {
    color: @lightRed;
}

.statusError {
    color: @basketBallOrange
}

.statusReset {
    color: @yellow
}

.cardView > .ui.grid {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
}

.cardView > .ui.grid > .row {
    width: 100% !important;
    padding-left: @base-padding * 2;
}

.cardView > .ui.grid > .column:not(.row), .ui.grid > .row > .column {
    padding-left: @base-padding;
    padding-right: @base-padding;
}

.supportEmailLink {
    color: @root-white;
}

/********* Card View - Recent Box ********/
.cardBox {
    float: left;
    border-radius: @baseBorderRadius*7;
    padding-top: @base-padding * 4;
    padding-bottom: @base-padding * 4;
    padding-left: @base-padding * 4;
    padding-right: @base-padding * 4;
    margin-top: @base-margin * 2;
    margin-bottom: @base-margin * 2;
    margin-right: @base-margin * 2;
    margin-left: @base-margin * 2;
    border: @primaryBorderWidth @main-white solid;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @main-white;
    font-size: @font-size-m;
    background-color: @tableLegendBackground;
    line-height: 28px;
    transition: transform .5s;
    width: 390px
}

/********* Sizes *********/
.cardBox.fit {
    width: fit-content   
}

.cardBox.s {
    width: 320px
}

.cardBox.xs {
    width: 200px
}

.cardBox > .title {
    width: 100%;
    font-size: @font-size-l;
}

.cardBox .rowCut {
    max-width: 200px;
}

.cardBox > .title > i.icon {
    float: right;
}

.cardBox > .title > .ui.dropdown.modify {
    float: right;
}

.cardBox > .title > .ui.checkbox.select{
    float: right;
}

.cardBox > .title > .ui.checkbox.select .box:before, .cardBox > .title > .ui.checkbox.select label:before {
    background: transparent;
    border: 3px solid @main-white;
    //padding: 5px;
    color: @main-white;
}
.cardBox > .title > .ui.checkbox.select .box:after, .cardBox > .title > .ui.checkbox.select label:after {
    background: transparent;
    color: @main-white;
}

.cardBox > .title > div > .ui.dropdown.modify > .dropdown.icon{
    display: none;
}

.cardBox > .alertTime {
    font-size: @font-size-m;
}

.cardBox .alertBox {
    margin-left: @base-margin;
    margin-top: @base-margin;
}

.cardBox .alertBox > i {
    position: relative;
    margin-right: @base-margin;
    margin-left: @base-margin * 2;
    top:-2px;
}

.cardBox > .alertBox> .alertItem {
    font-size: @font-size-s;
    color: @root-white;
    margin-top: @base-margin;
}

.cardBox .alertTable {
    margin-left: @base-margin * 4.5;
}

.cardBox .alertTable td:nth-child(2) {
    min-width: 108px;
}

.cardBox .alertTable td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.cardBox:hover {
    // transform: scale(1.008);
    cursor: inherit;
    z-index: 1;
}

.cardBox .fas.fa-edit:hover {
    transition: @transitionColor;
}

.cardBox .fas.fa-edit:hover {
    cursor: pointer;
    color: @tablePaginationColor;
}

.cardBox table {
    font-size:  @font-size-xs;
    border: none
}

.cardBox table tr td:nth-child(2) 
{
    font-family: @primary-font-black;
}

.cardBox table tr td {
    line-height: normal;
    padding-top: 2px;
}

/********* TableView ********/
.tableWrapper {
    overflow: hidden;
    margin-top: @base-padding;
    border-radius: @baseBorderRadius*6 @baseBorderRadius*6 0px 0px;
    border-left: @tableOutsideBorder;
    border-right: @tableOutsideBorder;
    border-top: @tableOutsideBorder;
    isolation: isolate;
}

.tableView {
    background: @darkGrey;
    height: calc(100vh - 228px);
    overflow: overlay;
}

.tableView table {
    width: 100%;
    color: @root-white;
    font-size: @font-size-xs;
    border-collapse: separate;
    border-spacing: 0;
}

.tableView {
    //border-radius: @baseBorderRadius*6;
    //border : @tableBorder;
    position: sticky;
    left: 0px;
    z-index: 10;
}

.tableView tbody {
    width: 100%;
}

.tableView tr {
    width: 100%;
}

.tableView th {
    height: 58px;
}

.tableView td {
    padding-bottom: @base-padding * 2;
    padding-top: @base-padding * 2;
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
}
.tableView th, .tableView td {
    border: @tableInnerBorder;
}

.tableView th {
    border-top: none;
    border-bottom: none;
    justify-content: center;
    align-items: center;
    border-bottom: @tableOutsideBorder;
}

.tableView th:first-child{
    border-left: none;
}

.tableView th:last-child {
    border-right: none;
}

.tableView tr:first-child {
    position: sticky;
    top: 0px;
    margin-right: @base-margin*9;
    background: @tableLegendBackground;
    z-index: 10001;
}

.tableView tr th:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
}

.tableView tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 10;
    background: @darkGrey;
}

.tableView tr th:last-child {
    position: sticky;
    right: 0px;
}

.tableView tr td:last-child {
    position: sticky;
    right: 0px;
}


.tableView tr:nth-child(2) td {
    border-top: 0px;
}

.tableView tr:last-child td {
    border-bottom: 0px;
}

.tableView tr td:first-child {
    border-left: none;
}

.tableView tr td:last-child {
    border-right: none;
}

.dataView  .tableFoot {
    margin-bottom: @base-margin;
    width: 100%;
}

.dataView .tableFoot .row {
    height: 52px;
    // background: @tableLegendBackground;
    border-radius: 0px 0px @baseBorderRadius*6 @baseBorderRadius*6;
    border: @tableOutsideBorder;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.dataView .tableFoot .row > .pagination {
    //float: right;
    padding-right: @base-padding * 5;
    //position: absolute;
    //right: 313px;
    //bottom: 20px;
}

.tableView th i {
    margin-right: @base-margin;
}

.tableView .fas.fa-edit:hover {
    cursor: pointer;
    color: @tablePaginationColor;
}

/**** TableView - Page Button ****/
.pageNumBtn {
    display: inline;
    color: @tablePaginationColor;
    border: @secondaryBorderWidth @tablePaginationColor solid;
    border-radius: @baseBorderRadius*2;
    padding: @base-padding * 2;
    padding-top: @base-padding / 2;
    padding-bottom: @base-padding;
    margin-left: @base-margin + 2;
    cursor: pointer;
    font-size: @font-size-xs;
}

.pageNumBtn.active {
    color:@main-white;
    border-color: @tableBorderColor;
}

.pageNumBtn.disabled {
    color: fade(@main-white, 30%);
    border-color: fade(@main-white, 30%);
    cursor: default;
    pointer-events: none;
}

/********* Detailed View - Detailed Box ********/
.detailedBox {
    float: left;
    border-radius: @baseBorderRadius*7;
    padding-top: @base-padding * 4;
    padding-bottom: (@base-padding * 2);
    padding-left: @base-padding * 2;
    padding-right: @base-padding * 2;
    margin-top: @base-margin*2;
    margin-bottom: @base-margin*3;
    margin-right: (@base-margin*3) + 1;
    margin-left: (@base-margin*3) + 1;
    border: @primaryBorderWidth @main-white solid;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    color: @main-white;
    font-size: @font-size-m;
    background-color: @tableLegendBackground;
    transition: transform .5s;
    width: 185px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
}

.detailedBox:hover {
    transform: scale(1.008);
    cursor: inherit;
    z-index: 1;
}

.detailedBox.active {
    border-color: @purple;
}

.detailedBox.detailCard {
    border-color: @purple;
    padding-left: @base-padding * 4;
    padding-right: @base-padding * 4;
    width: 100%;
}

.detailedBox > .circle {
    border-radius: 50%;
    background-color: @lightRed;
    box-shadow: 0 0 8px -2px @lightRed;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    right: -16px;
    border-width: 0px;
    font-size: @font-size-s;
}

.detailedBox > .circle.good {
    background-color:  @green;
} 

.detailedBox > .circle.bad {
    background-color: @red;
}

.detailedBox > .content {
    display: inline-block;
    width: 100%;
    height: 100%
}

.detailedBox > .content > .buttonWrapper {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%
}

.detailedBox .link {
    cursor: pointer;
    // line-height: initial;
}

.detailedBox > .detailedMenu.active {
    display: block;
}

.detailCard .detailCardTitle {
    height: 20%; 
    width: 95%; 
}

.detailCard .content > .detailCardScroll{
    width: 100%; 
    height: 80%; 
    overflow-y: auto
}

.detailCard .content > .detailCardContent {
    padding-left: @base-padding * 2; 
    padding-right: @base-padding * 2; 
    font-size: @font-size-xs
}

.detailCard .detailCardContent > .info {
    font-size: @font-size-2xs; 
    padding-left: @base-padding * 5;
    margin-bottom: @base-margin * 2;
}

.detailedBox .content > .detailBoxContent {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.detailBoxContent .byDeviceTitle {
    text-align: center;
    font-size: @font-size-xs;
    padding-top: @base-padding;
    padding-bottom: @base-padding;
    width: 100%;
}

.detailBoxContent .bySiteTitle {
    text-align: center;
    font-size: @font-size-xs;
    width: 100%;
}

.detailedBox .content > .detailBoxContent > .link {
    font-size: @font-size-2xs; 
    margin-bottom: @base-margin * 2
}

.detailedBox .content > .detailBoxContent > .link[disabled] {
    opacity: .6;
    pointer-events: none;
    cursor: default;
}

.content > .detailBoxContent > .byTypeMainIcon {
    margin-right: @base-margin;
    font-size: @font-size-5xl + 6
}

/************************************/

/****** Card View - Scroller ******/
.ui.dropdown .menu > .scroller {
    white-space: normal;
}

.loader.modalLoader.active {
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-bottom: @base-margin*20;
}

.viewer .noResults {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: @grey;
    font-size: @font-size-xl;
    //font-weight: bolder;
    font-family: @secondary-font-black;
}

.cardBox i[type=good] {
    color: @green;
}

.cardBox i[type=bad] {
    color: @lightRed;
}

/********** Schedule View *********/
.scheduleView {
    display: flex;
    height: 100%;
    padding-top: @base-padding;
    flex-direction: column;
}

.scheduleCard {
    height: auto;
    width: 100%;
    display: flex;
    max-height: @scheduleBarHeight * @scheduleBarCountInactive + (@scheduleCardTablePadding * @scheduleBarCountInactive);
    min-height: @scheduleBarHeight * @scheduleBarCountInactive + (@scheduleCardTablePadding * @scheduleBarCountInactive);
    margin-bottom: @base-margin * 2;
    overflow: hidden;
    border-radius: @baseBorderRadius * 6;
}

.scheduleCard.active {
    max-height: none;
}

.scheduleCard > div:first-child {
    background-color: @purple;
    width: 15%;
    border-top-left-radius: @baseBorderRadius * 6;
    border-bottom-left-radius: @baseBorderRadius * 6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: @font-size-4xl;
    border: @primaryBorder;
    position: relative;
}

.scheduleCard > div:last-child {
    width: 85%;
    border-top-right-radius: @baseBorderRadius * 6;
    border-bottom-right-radius: @baseBorderRadius * 6;
    border-right: @primaryBorder;
    border-top: @primaryBorder;
    border-bottom: @primaryBorder;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
}

.scheduleCard table {
    border-spacing: 0px;
}

.scheduleCard table tbody > tr > th,
.scheduleCard table tbody > tr > td {
    height: @scheduleBarHeight;
}

.scheduleCard table tbody > tr:first-child td {
    border-left-width: 0px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-style: solid;
}

.scheduleCard table tbody > tr:first-child td:last-child {
    border-right-width: 0px;
}

.scheduleCard > div:first-child > .expander {
    position: absolute;
    bottom: 8px;
    font-size: @font-size-xl;
    right: 8px;
    cursor: pointer;
}

.ui.dropdown.scheduleBarWrapper {
    height: 100%;
    width: 100%;
}

.scheduleCard .scheduleBar:hover {
    background-color: var(--hover-color) !important;
}

.scheduleBarMenu .scheduleBarActions {
    display: flex;
    justify-content: center;
    padding-top: @base-padding * 2;
}

.scheduleBarMenu .scheduleBarActions i:hover {
    cursor: pointer;
}

.subMenu .subMenuBackButton {
    color: @lightBlue;
    font-size: @font-size-2xs;
    height: @font-size-2xs;
}

.subMenu .subMenuTitle {
    font-family: @primary-font-black;
}

.subMenu .subMenuTitleHidden {
    color: transparent;
    height: @font-size-2xs;
}

.subMenu.menu > .item.backItem {
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.subMenu .subMenuBackButton i,
.subMenu .subMenuTitleHidden i {
    margin-right: @base-margin;
}

.ui.dropdown.actionMenu  > .dropdown.icon {
    display: none;
}

.scrollController.listView > .scrollView {
    width: 100%
}

.listView > .scrollView > .listItem {
    border-bottom: @secondaryBorderWidth solid;;
    padding: @base-padding * 3 @base-padding;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    font-family: @primary-font-bold;
    letter-spacing: @letterSpacing;
    font-size: @font-size-xs;
    margin: 0 @base-margin * 2;
    margin: 0 @base-margin - 1;
}

.listView > .scrollView > .listItem > .listLeft {
    width: 100%
}

.listView > .scrollView > .listItem > .listRight {
    width: 36px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectedDeviceLabel {
    font-size: @font-size-xs;
}

.detailedBox .actionMenu {
    display: flex;
    justify-content: center;
}

.detailedBox .ui.dropdown.actionMenu > .left.menu {
    left: auto!important;
    right: auto!important;
}