@import './variables.less';

.carousel {
    color: @root-white 
}

.carousel > div {
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: @base-margin*6;
}

.carousel > div > i {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10000;
    font-size: @font-size-xl;
}

.carousel > div > i[disable="true"] {
    color: fade(@root-white, 20%);
}

.carousel > div > i[disable="false"]:hover {
    color: fade(@root-white, 50%);
    cursor: pointer
}

.carousel .slideHolder {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; 
    height: 225px;
    clip-path: inset(0px 0px -100px 0px);
    margin-right: @base-margin*2;
    margin-left: @base-margin*2;
    transition: .5s margin-left;
}

.slideHolder > div {
    width: 100%;
}

.carousel .slideHolder .slide {
    margin-right: @base-margin * 4;
    margin-left: @base-margin * 2;
    position: relative;
}

.carousel .ui.menu.carousel-dots .item {
    padding-left: @base-padding*2;
    padding-right: @base-padding*2;
    color: @root-white;
}

.carousel .ui.menu.attached.carousel-dots {
    display: flex;
    justify-content: center;
    background: transparent;
    border: none;
}

.carousel .ui.menu.carousel-dots .item.active {
    color: fade(@root-white, 20%);
    background: transparent;
}

.carousel .ui.menu.carousel-dots .item:hover {
    color: fade(@root-white, 50%);
    background: transparent;
}

.carousel .ui.menu.carousel-dots .item:focus {
    color: fade(@root-white, 20%);
}

.carousel .ui.menu.carousel-dots .item::before {
    background: none;
}

.ui.modal.aresModal .error.field .ui.input input {
    border: @secondaryBorderWidth solid @red;
}

.ui.modal.aresModal .carousel .ui.selection.dropdown.carouselDropdown {
    width: 223px; 
    padding-right: 6px;
}

.ui.modal.aresModal .ui.selection.dropdown.carouselDropdown > .divider.text {
    width: 190px;
}

.ui.modal.aresModal .carousel .ui.selection.dropdown.carouselDropdown > .menu > .item i {
    margin-right: @base-margin;
}

.ui.modal.aresModal .carousel .ui.selection.dropdown.carouselDropdown > i {
    position: absolute;
    top: 10px
}