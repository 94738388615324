@import "./variables.less";
.inLineInput {
    display: inline-block; 
    padding-left: 0.5em;
    padding-right: 0.5em;
    justify-content: justify center;
    width: 100%
}

.inLineInput .field:first-child {
    padding: 0px @base-padding 0px 0px;

}

.inLineInput .field:last-child {
    padding: 0px 0px 0px @base-padding;
    
}